import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import api from "../../services/api";

import { LoadingPage } from "../Loading";
import { Carousel } from "../../components/Carousel";
import { Footer } from "../../components/Footer";
// import Carroseul from '../../components/carousel';

import "./styles.css";
import logoImg from "../../assets/logo.svg";
import estudanteImg from "../../assets/estudante.svg";
import empresaImg from "../../assets/empresa.svg";

export function Inicio() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const { logged } = useSelector((state) => state.UserReducer);
  const { role } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    const getUser = async () => {
      await api.get("/user/index").then((res) => {
        setUserName(res.data.user.username);
        setLoading(false);
      });
    };

    const getCompany = async () => {
      await api.get("/company/index").then((res) => {
        setCompanyEmail(res.data.company.email);
        setLoading(false);
      });
    };

    if (loading && logged) {
      if (role === "student" || role === "admin") {
        getUser();
      } else if (role === "company") {
        getCompany();
      }
    } else {
      setLoading(false);
    }
  }, [loading, logged, role]);

  const handleLogout = async () => {
    await api.get("/user/logout")
      .then((res) => {
        dispatch({ type: "resetUserState" }); //vai resetar o reducer do user
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data.message);
        }
      });
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <div className="init">
      <div className="content-container">
        <div className="presentation-container">
          <img className="logo" src={logoImg} alt="logo" />
          
          <p className="textoInicial">
            O EduLink é a plataforma de avaliação de egressos que irá conectar a avaliação de cursos de Pós-graduação 
            da área de Biotecnologia com as instituições de ensino e o mercado de trabalho, contando com a colaboração de empresas 
            parceiras que poderão selecionar os perfis de profissionais adequados e devidamente preparados para as tarefas cotidianas.
          </p>

          <div className="login">
            <h3 className="titulo">Entrar</h3>
            <a href="../loginegresso">
              <button type="button">
                <img src={estudanteImg} alt="icone de capelo" />
                Egresso
              </button>
            </a>
            <br />
            <a href="../loginempresas">
              <button type="button">
                <img src={empresaImg} alt="icone de predio" />
                Empresa
              </button>
            </a>
          </div>
        </div>

        <div className="carousel-container">
          <Carousel />
        </div>
      </div>

      <Footer 
        logged={logged}
        role={role}
        userName={userName}
        companyEmail={companyEmail}
        handleLogout={handleLogout}
      />
    </div>
  );
}
