import React, { useState } from "react";
import Estados from "../../components/estados";
import { Cidades } from "../../components/Cidades";

import setaImg from "../../assets/seta.svg";
import setaImg2 from "../../assets/seta-a.svg";

export default function Sidebar(props) {
  const [isOpen, setIsOpen] = useState(false);

  const [applyFilter, setApplyFilter] = props.applyFilter;
  const setLoading = props.loading;

  const [state, setState] = props.state;
  const setCity = props.city;
  const setGraduation = props.graduation;
  const setPosGraduation = props.posGraduation;
  const setConclusionYear = props.conclusionYear;

  const handleSideBar = () => {
    document.getElementById("sidebar-container").classList.toggle("activy");
    setIsOpen(!isOpen);
  };

  return (
    <div id="sidebar-container">
      <div className="filtros-lateral">
        <button className="seta-img" onClick={() => handleSideBar()}>
          {isOpen ? (
            <img src={setaImg} alt="seta" />
          ) : (
            <img src={setaImg2} alt="seta" />
          )}
        </button>
        <div className="filtros-lateral-title">
          <h2>Filtros</h2>
        </div>
        <div className="locale-container">
          <div className="locale-title">
            <h3>Localidade</h3>
          </div>
          <div className="state-container">
            <Estados setState={setState} />
          </div>
          <div className="locale-linha-1"></div>
          <div className="city-container">
            <Cidades state={state} setCity={setCity} />
          </div>
        </div>
        <div className="formacao-container">
          <div className="formacao-title">
            <h3>Formação</h3>
          </div>
          <div className="graduação-container">
            <label htmlFor="graduação">Graduação</label>
            <select
              id="graduação"
              onChange={(event) => setGraduation(event.target.value)}
            >
              <option value="">Selecione</option>
              <option value="Medicina">Medicina</option>
              <option value="Enfermagem">Enfermagem</option>
              <option value="Fisioterapia">Fisioterapia</option>
              <option value="Biomedicina">Biomedicina</option>
              <option value="Ciências Biológicas">Ciências Biológicas</option>
              <option>Outros</option>
            </select>
          </div>
          <div className="graduação-linha"></div>
          <div className="pós-container">
            <label htmlFor="pós">Área de Pós-Graduação</label>
            <select
              id="pós"
              onChange={(event) => setPosGraduation(event.target.value)}
            >
              <option value="">Selecione</option>
              <option value="Medicina">Medicina</option>
              <option value="Enfermagem">Enfermagem</option>
              <option value="Fisioterapia">Fisioterapia</option>
              <option value="Biomedicina">Biomedicina</option>
              <option value="Ciências Biológicas">Ciências Biológicas</option>
              <option>Outros</option>
            </select>
          </div>
          <div className="pós-linha"></div>
          <div className="tempo-f-container">
            <div className="tempo-label-1">
              <label htmlFor="temp-f">Ano de formação</label>
            </div>

            <input
              type="number"
              min="1960"
              max="2100"
              onChange={(event) => setConclusionYear(event.target.value)}
            />
          </div>
        </div>
        <div className="aplicar">
          <button type="button" onClick={() => {
            setApplyFilter(!applyFilter)
            setLoading(true)
          }}>
            <div className="aplicar-text">
              <span>Aplicar</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
