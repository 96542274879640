import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify"
import api from "../../../services/api";

import logoImg from "../../../assets/logo.svg";

import "./styles.css";

export function LoginForm() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();

    const loginData = {
      email: email,
      password: password,
    };

    await api.post("/user/loginadm", loginData)
      .then(res => {
        dispatch({ type: "setUserLogged", payload: true });
        window.location.href = '/';
      }).catch(error => {
        if(error.response) {
          toast.error(error.response.data.message);
        }
      });
  }

  return(
    <div className="login-container-empresa">
      <header>
        <a href="/"><img src={logoImg} alt="logo"/> </a>
      </header>

      <form 
        className="login-form-empresa"
        onSubmit={event => handleLogin(event)}
      >
        <p>E-mail</p>
        <input 
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />

        <p>Senha</p>
        <input 
          type="password"
          placeholder="Senha"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />

        <button type="submit">
          Entrar
        </button>
      </form>

    </div>
  );
}
