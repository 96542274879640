import React from "react";
import Voltar from "../../../components/Voltar";

import logoImg from "../../../assets/logo.svg";

import "./styles.css";

export function Header() {
  return (
    <div className="lista-forms-header">
      <header>
        <a href="/">
          <img src={logoImg} alt="logo"/>
        </a>
      </header>

      <Voltar path={""}/>

      <h1>Respostas do Formulário</h1>
    </div>
  );
}
