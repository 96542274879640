import React from "react";

import "./styles.css";

import logoImg from "../../assets/logo.svg";
import thankImg from "../../assets/agradecimentoImg.svg";

function Alerta() {
  alert("Agradecemos a colaboração com o preenchimento do Formulário!");
}

export function Agradecimentos() {
  return (
    <div className="thanks-container">
      <header>
        <img src={logoImg} alt="logo" />
      </header>
      <div className="text-container">
        <h2>
          Agradecemos as respostas enviadas! Esclarecemos que sua contribuição é
          muito importante para a melhoria da qualidade de ensino da
          Universidade.
        </h2>
      </div>
      <div className="image-container">
        <img src={thankImg} alt="imagem" />
      </div>
      <div className="question-container">
        <p>
          Você gostaria de preencher seu currículo que poderá ser direcionado à
          oportunidades na área de Biotecnologia?
        </p>
        <div className="button-container">
          <a href="../curriculoForms">
            <button className="buttonConfirmar" type="submit">
              Sim
            </button>
          </a>
          <a href="../">
            <button className="buttonRecusar" type="submit" onClick={Alerta}>
              Não
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
