import React from "react";
import { Header } from "./Header";
import { FormsList } from "./FormsList";

export function ListaForms() {
  return (
    <>
      <Header/>
      <FormsList/>
    </>
  );
}
