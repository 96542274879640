import React from "react";

import "./styles.css";

export function Pg3(props) {
  const setAdministration = props.setAdministration; //question7
  const setAcess = props.setAcess; //question8
  const [hours, setHours] = props.setHours; //question9
  const [organization, setOrganization] = props.setOrganization; //question10
  const [activities, setActivities] = props.setActivities; //question11
  const [activitiesText, setActivitiesText] = props.setActivitiesText;
  const setWorkRegime = props.setWorkRegime; //question12
  const [benefits, setBenefits] = props.setBenefits; //question13
  const [benefitsText, setBenefitsText] = props.setBenefitsText;
  const setSatisfaction = props.setSatisfaction; //question14
  const setOpportunities = props.setOpportunities; //question15
  const setFirstJob = props.setFirstJob; //question16
  const setJobBeginning = props.setJobBeginning; //question17
  const setSalary = props.setSalary; //question18
  const setChangeJob = props.setChangeJob; //question19a
  const setKeepJob = props.setKeepJob; //question19b
  const setQuitJob = props.setQuitJob; //question19c
  const [help, setHelp] = props.setHelp; //Para concluir seu curso voce recebeu...
  const [upgrades, setUpgrades] = props.setUpgrades; //voce obteve quais melhorias...

  const handleAddActivies = (activie) => {
    const newActivies = activities;
    newActivies.unshift(activie);
    
    const sliceArr = newActivies.slice(0, 3);
    setActivities(sliceArr);
    const activiesInText = sliceArr.join(", ");

    setActivitiesText(activiesInText);
  };

  const handleAddAcitivie = (activie) => {
    setActivitiesText(activie);
    setActivities([]);
  }

  const handleBenefits = (benefit) => {
    const newBenefits = benefits;
    const found = newBenefits.indexOf(benefit);

    if(found === -1) {
      newBenefits.push(benefit);
    } else {
      newBenefits.splice(found, 1);
    }

    const formattBenefits = new Set(newBenefits);
    const benefitsInText = [...formattBenefits].join(", ");
    
    setBenefits([...formattBenefits]);
    setBenefitsText(benefitsInText);
  }

  return (
    <form className="answer-form-pg3">
      <div className="left-column-pg3">
        <div className="administration-container-pg3">
          <h6>7) Nível de administração*</h6>
          <div className="administration-checkbox-1-pg3">
            <input
              type="radio"
              name="administrationType"
              id="public"
              value="Pública"
              onChange={(event) => setAdministration(event.target.value)}
            />
            <label for="administration">Pública</label>
          </div>
          <div className="administration-checkbox-2-pg3">
            <input
              type="radio"
              name="administrationType"
              id="pivate"
              value="Privada"
              onChange={(event) => setAdministration(event.target.value)}
            />
            <label for="administration">Privada</label>
          </div>

          <div className="hours-container-pg3">
            <h6>9) Carga horária semanal até: *</h6>
            <div className="hours-checkbox-1-pg3">
              <input
                type="radio"
                name="hoursType"
                id="20"
                value="20"
                checked={hours === "20"}
                onChange={(event) => setHours(event.target.value)}
              />
              <label for="hours">20 horas</label>
              <div className="hours-checkbox-4-pg3">
                <label for="hours">Outro: </label>
                <input
                  type="number"
                  min="41"
                  value={hours}
                  onChange={(event) => setHours((event.target.value).toString())}
                />
              </div>
            </div>
            <div className="hours-checkbox-2-pg3">
              <input
                type="radio"
                name="hoursType"
                id="30"
                value="30"
                checked={hours === "30"}
                onChange={(event) => setHours(event.target.value)}
              />
              <label for="hours">30 horas</label>
            </div>
            <div className="hours-checkbox-3-pg3">
              <input
                type="radio"
                name="hoursType"
                id="40"
                value="40"
                checked={hours === "40"}
                onChange={(event) => setHours(event.target.value)}
              />
              <label for="hours">40 horas</label>
            </div>
          </div>
        </div>
        <div className="activities-container-pg3">
          <h6>
            11) Escolha até três atividades que você desenvolve mais <br/>
            frequentemente nesta modalidade de inserção profissional:*
          </h6>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              id="Pesquia"
              value="Pesquisa"
              checked={activities.find((act) => act === "Pesquisa")}
              onChange={(event) => handleAddActivies(event.target.value)}
            />
            <label for="activities">Pesquisa</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              id="EnsinoGraduacao"
              value="Ensino de graduação"
              checked={activities.find((act) => act === "Ensino de graduação")}
              onChange={(event) => handleAddActivies(event.target.value)}
            />
            <label for="activities">Ensino de graduação</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              id="AtividadeAdministrativa"
              value="Atividades administrativas"
              checked={activities.find((act) => act === "Atividades administrativas")}
              onChange={(event) => handleAddActivies(event.target.value)}
            />
            <label for="activities">Atividades administrativas</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              id="GestaoSaude"
              value="Gestão na área da saúde"
              checked={activities.find((act) => act === "Gestão na área da saúde")}
              onChange={(event) => handleAddActivies(event.target.value)}
            />
            <label for="activities">Gestão na área da saúde</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              id="AtendimentoSaude"
              value="Atendimento de saúde"
              checked={activities.find((act) => act === "Atendimento de saúde")}
              onChange={(event) => handleAddActivies(event.target.value)}
            />
            <label for="activities">Atendimento de saúde</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              id="Gestao"
              value="Gestão"
              checked={activities.find((act) => act === "Gestão")}
              onChange={(event) => handleAddActivies(event.target.value)}
            />
            <label for="activities">Gestão</label>
          </div>
          <div className="hours-checkbox-5-pg3">
            <label for="activities">Outro (Especifique):</label>
            <input
              type="text"
              id="Outro"
              value={activitiesText}
              maxlength="40"
              onChange={(event) => handleAddAcitivie(event.target.value)}
            />
          </div>
        </div>
        <div className="benefits-container-pg3">
          <h6>13) Benefícios*</h6>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              id="AssistenciaSaude"
              value="Assistência saúde"
              checked={benefits.find((ben) => ben === "Assistência saúde")}
              onClick={(event) => handleBenefits(event.target.value)}
            />
            <label for="benefits">
              Assistência saúde (médica, odontológica, plano hospitalar, etc)
            </label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              id="ALimentaçao"
              value="Alimentação"
              checked={benefits.find((ben) => ben === "Alimentação")}
              onClick={(event) => handleBenefits(event.target.value)}
            />
            <label for="benefits">
              Alimentação (ticket, restaurante no local de trabalho, cesta
              básica, etc)
            </label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              id="Previdencia"
              value="Plano de previdência"
              checked={benefits.find((ben) => ben === "Plano de previdência")}
              onClick={(event) => handleBenefits(event.target.value)}
            />
            <label for="benefits">Plano de previdência</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              id="Transporte"
              value="Auxílio transporte"
              checked={benefits.find((ben) => ben === "Auxílio transporte")}
              onClick={(event) => handleBenefits(event.target.value)}
            />
            <label for="benefits">Auxílio transporte</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              id="ParticipaçaoLucro"
              value="Participação e/ou resultados"
              checked={benefits.find((ben) => ben === "Participação e/ou resultados")}
              onClick={(event) => handleBenefits(event.target.value)}
            />
            <label for="benefits">Participação e/ou resultados</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              id="CarroOrganizaçao"
              value="Carro da organização"
              checked={benefits.find((ben) => ben === "Carro da organização")}
              onClick={(event) => handleBenefits(event.target.value)}
            />
            <label for="benefits">Carro da organização</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              id="Moradia"
              value="Moradia"
              checked={benefits.find((ben) => ben === "Moradia")}
              onClick={(event) => handleBenefits(event.target.value)}
            />
            <label for="benefits">Moradia</label>
          </div>
        </div>
        <div className="riseUp-container-pg3">
          <h6>
            15) Como você percebe as oportunidades para seu crescimento
            profissional nesta organização?*
          </h6>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="riseUpType"
              id="NenhumaOportunidade"
              value="Nenhuma oportunidade"
              onChange={(event) => setOpportunities(event.target.value)}
            />
            <label for="opportunities">Nenhuma oportunidade</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="riseUpType"
              id="PoucaOportunidade"
              value="Pouca oportunidade"
              onChange={(event) => setOpportunities(event.target.value)}
            />
            <label for="opportunities">Pouca oportunidade</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="riseUpType"
              id="MuitaOportunidade"
              value="Muita oportunidade"
              onChange={(event) => setOpportunities(event.target.value)}
            />
            <label for="opportunities">Muita oportunidade</label>
          </div>
        </div>

        <div className="salary-container-pg3">
          <h6>
            18) Indique sua renda mensal assinalando uma das alternativas:*
          </h6>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="Nenhuma"
              value="Nenhuma renda"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">Nenhuma renda</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="560"
              value="Até R$560 reais"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">Até R$560 reais</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="501-1200"
              value="De R$561 a R$1.200"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">De R$561 a R$1.200</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="1201-2000"
              value="De R$1.201 a R$2.000"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">De R$1.201 a R$2.000</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="2001-3000"
              value="De R$2.001 a R$3.000"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">De R$2.001 a R$3.000</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="3001-5000"
              value="De R$3.001 a R$5.000"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">De R$3.001 a R$5.000</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="5001-7000"
              value="De R$5.001 a R$7.000"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">De R$5.001 a R$7.000</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="7001-10000"
              value="De R$7.001 a R$10.000"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">De R$7.001 a R$10.000</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="10001-20000"
              value="De R$10.001 a R$20.000"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">De R$10.001 a R$20.000</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="salaryType"
              id="AcimaDe20000"
              value="Acima de R$20.000"
              onChange={(event) => setSalary(event.target.value)}
            />
            <label for="salary">Acima de R$20.000</label>
          </div>
        </div>
        <div className="professional-performance-container-pg3">
          <h6>19) Como você vê sua atuação profissional daqui a um ano:*</h6>
          <div className="professional-performance--inputs-container-pg3">
            <div className="performance-checkbox-pg3">
              <label for="changeJob">
                Gostaria de mudar de emprego mantendo o que eu faço.
              </label>
              <div className="performance-checkboxs-pg3">
                <div className="muito-container-pg3">
                  <label for="changeJob">Muito</label>
                  <input
                    type="radio"
                    name="changeJobType"
                    id="Muito"
                    value="Muito"
                    onChange={(event) => setChangeJob(event.target.value)}
                  />
                </div>
                <div className="pouco-container-pg3">
                  <label for="changeJob">Pouco</label>
                  <input
                    type="radio"
                    name="changeJobType"
                    id="Pouco"
                    value="Pouco"
                    onChange={(event) => setChangeJob(event.target.value)}
                  />
                </div>
                <div className="nao-container-pg3">
                  <label for="changeJob">Não</label>
                  <input
                    type="radio"
                    name="changeJobType"
                    id="Nao"
                    value="Não"
                    onChange={(event) => setChangeJob(event.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="performance-checkbox-2-pg3">
              <label for="keepJob">
                Gostaria de mudar dentro da minha área de estudo, mantendo o
                emprego.
              </label>
              <div className="performance-checkboxs-2-pg3">
                <div className="muito-container-2-pg3">
                  <label for="keepJob">Muito</label>
                  <input
                    type="radio"
                    name="keepJobType"
                    id="Muito"
                    value="Muito"
                    onChange={(event) => setKeepJob(event.target.value)}
                  />
                </div>
                <div className="pouco-container-2-pg3">
                  <label for="keepJob">Pouco</label>
                  <input
                    type="radio"
                    name="keepJobType"
                    id="Pouco"
                    value="Pouco"
                    onChange={(event) => setKeepJob(event.target.value)}
                  />
                </div>
                <div className="nao-container-2-pg3">
                  <label for="keepJob">Não</label>
                  <input
                    type="radio"
                    name="keepJobType"
                    id="Nao"
                    value="Não"
                    onChange={(event) => setKeepJob(event.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="performance-checkbox-3-pg3">
              <label for="quitJob">
                Gostaria de não atuar mais no meu emprego.
              </label>
              <div className="performance-checkboxs-3-pg3">
                <div className="muito-container-3-pg3">
                  <label for="quitJob">Muito</label>
                  <input
                    type="radio"
                    name="quitJobType"
                    id="Muito"
                    value="Muito"
                    onChange={(event) => setQuitJob(event.target.value)}
                  />
                </div>
                <div className="pouco-container-3-pg3">
                  <label for="quitJob">Pouco</label>
                  <input
                    type="radio"
                    name="quitJobType"
                    id="Pouco"
                    value="Pouco"
                    onChange={(event) => setQuitJob(event.target.value)}
                  />
                </div>
                <div className="nao-container-3-pg3">
                  <label for="quitJob">Não</label>
                  <input
                    type="radio"
                    name="quitJobType"
                    id="Nao"
                    value="Não"
                    onChange={(event) => setQuitJob(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="left-row-container-pg3">
          <div className="help-container-pg3">
            <h6>
              Para concluir seu curso você recebeu algum tipo de ajuda ou
              incentivo vindo da empresa ou instituição que trabalha?*
            </h6>
            <textarea
              cols="35"
              rows="5"
              id="help"
              placeholder="Escreva aqui..."
              value={help}
              maxlength="1000"
              onChange={(event) => setHelp(event.target.value)}
            />
          </div>
          <div className="upgrades-container-pg3">
            <h6>
              Você obteve quais melhorias decorrentes da sua atual formação?*
            </h6>
            <textarea
              cols="35"
              rows="5"
              id="upgrades"
              placeholder="Escreva aqui..."
              value={upgrades}
              maxlength="1000"
              onChange={(event) => setUpgrades(event.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="right-column-2-pg3">
        <div className="acess-container-pg3">
          <h6>8) Forma de acesso*</h6>
          <div className="acess-checkbox-pg3">
            <input
              type="radio"
              name="acessType"
              id="ProcessoSeletivo"
              value="Processo seletivo ou concurso"
              onChange={(event) => setAcess(event.target.value)}
            />
            <label for="acess">Processo seletivo ou concurso</label>
          </div>
          <div className="acess-checkbox-pg3">
            <input
              type="radio"
              name="acessType"
              id="Indicaçao"
              value="Indicação"
              onChange={(event) => setAcess(event.target.value)}
            />
            <label for="acess">Indicação</label>
          </div>
          <div className="acess-checkbox-pg3">
            <input
              type="radio"
              name="acessType"
              id="Convite"
              value="Convite"
              onChange={(event) => setAcess(event.target.value)}
            />
            <label for="acess">Convite</label>
          </div>
        </div>

        <div className="organization-container-pg3">
          <h6>10) Natureza da organização*</h6>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="organizationType"
              id="HospitalPublico"
              value="Hospital público"
              checked={organization === "Hospital público"}
              onChange={(event) => setOrganization(event.target.value)}
            />
            <label for="organization">Hospital público</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="organizationType"
              id="FundaçaoHospitalar"
              value="Fundação hospitalar"
              checked={organization === "Fundação hospitalar"}
              onChange={(event) => setOrganization(event.target.value)}
            />
            <label for="organization">Fundação hospitalar</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="organizationType"
              id="OrgaoMunicipal"
              value="Órgao de administração pública Municipal"
              checked={organization === "Órgao de administração pública Municipal"}
              onChange={(event) => setOrganization(event.target.value)}
            />
            <label for="organization">
              Órgão de administração pública Municipal
            </label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="organizationType"
              id="OrgaoFederak"
              value="Órgão de administração pública Federal"
              checked={organization === "Órgão de administração pública Federal"}
              onChange={(event) => setOrganization(event.target.value)}
            />
            <label for="organization">
              Órgão de administração pública Federal
            </label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="organizationType"
              id="ProcessoSeletivo"
              value="Empresa particular"
              checked={organization === "Empresa particular"}
              onChange={(event) => setOrganization(event.target.value)}
            />
            <label for="organization">Empresa particular</label>
          </div>
          <div className="organization-checkbox-2-pg3">
            <label for="organization">Outro (Especifique):</label>
            <input
              type="text"
              id="Outro"
              value={organization}
              maxlength="40"
              onChange={(event) => setOrganization(event.target.value)}
            />
          </div>
        </div>

        <div className="work-regime-container-pg3">
          <h6>12) Regime de trabalho*</h6>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="workRegimeType"
              id="RegimeEstatutario"
              value="Regime estatutário"
              onChange={(event) => setWorkRegime(event.target.value)}
            />
            <label for="workRegime">Regime estatutário</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="workRegimeType"
              id="RegimeCLT"
              value="Regime CLT"
              onChange={(event) => setWorkRegime(event.target.value)}
            />
            <label for="workRegime">Regime CLT</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="workRegimeType"
              id="Autonomo"
              value="Prestador de serviço atuando como autônomo"
              onChange={(event) => setWorkRegime(event.target.value)}
            />
            <label for="workRegime">
              Prestador de serviço atuando como autônomo
            </label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="workRegimeType"
              id="Terceirizado"
              value="Prestador de serviço atuando como tercerizado"
              onChange={(event) => setWorkRegime(event.target.value)}
            />
            <label for="workRegime">
              Prestador de serviço atuando como terceirizado
            </label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="workRegimeType"
              id="Bolsista"
              value="Bolsista"
              onChange={(event) => setWorkRegime(event.target.value)}
            />
            <label for="workRegime">Bolsista</label>
          </div>
        </div>

        <div className="satisfaction-container-pg3">
          <h6>14) Como você se sente nessa organização?*</h6>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="satisfactionType"
              id="Satisfeito"
              value="Satisfeito"
              onChange={(event) => setSatisfaction(event.target.value)}
            />
            <label for="satisfaction">Satisfeito</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="satisfactionType"
              id="Insatisfeito"
              value="Insatisfeito"
              onChange={(event) => setSatisfaction(event.target.value)}
            />
            <label for="satisfaction">Insatisfeito</label>
          </div>
          <div className="hours-checkbox-2-pg3">
            <input
              type="radio"
              name="satisfactionType"
              id="indiferente"
              value="Indiferente"
              onChange={(event) => setSatisfaction(event.target.value)}
            />
            <label for="satisfaction">Indiferente</label>
          </div>
        </div>

        <div className="first-job-container-pg3">
          <h6>
            16) Este foi o primeiro emprego (trabalho) na sua área de estudo?*
          </h6>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="firstJobType"
              id="Sim"
              value="Sim"
              onChange={(event) => setFirstJob(event.target.value)}
            />
            <label for="firstJob">Sim</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="firstJobType"
              id="Nao"
              value="Não"
              onChange={(event) => setFirstJob(event.target.value)}
            />
            <label for="firstJob">Não</label>
          </div>
        </div>

        <div className="start-job-container-pg3">
          <h6>17) Este emprego teve início:*</h6>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="jobBegnningType"
              id="antesDaPos"
              value="Antes de iniciar o curso de pós-graduação"
              onChange={(event) => setJobBeginning(event.target.value)}
            />
            <label for="jobBeginning">
              Antes de iniciar o curso de pós-graduação
            </label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="jobBegnningType"
              id="DuanteAPos"
              value="Durante o curso de pós-graduação"
              onChange={(event) => setJobBeginning(event.target.value)}
            />
            <label for="jobBeginning">Durante o curso de pós-graduação</label>
          </div>
          <div className="organization-checkbox-pg3">
            <input
              type="radio"
              name="jobBegnningType"
              id="DepoisDaPos"
              value="Depois de finalizar o curso de pós-graduação"
              onChange={(event) => setJobBeginning(event.target.value)}
            />
            <label for="jobBeginning">
              Depois de finalizar o curso de pós-graduação
            </label>
          </div>
        </div>
      </div>
    </form>
  );
}
