import React from "react";

import "./styles.css";

export function Pg4(props) {
  const [otherSkills, setOtherSkills] = props.setOtherSkills; //question20
  const [wantedSkills, setWantedSkills] = props.setWantedSkills; //question21
  const [strongPoints, setStrongPoints] = props.setStrongPoints; //question22
  const [weakPoints, setWeakPoints] = props.setWeakPoints; //question23
  const setGraduationImprove = props.setGraduationImprove; //question24
  const setProfessionalRise = props.setProfessionalRise; //question25
  const setProductDeveloped = props.setProductDeveloped; //question26
  const setProductApplicability = props.setProductApplicability; //question27
  const setHelpPartnership = props.setHelpPartnership; //question28
  const setApplicability = props.setApplicability; //question29
  const setPleased = props.setPleased; //question30


  return (
    <form className="answer-form-pg4">
      <div className="left-column-pg4">
        <div className="other-skills-container-pg4">
          <h6>
            20) Quais outras competências, que não são as listadas
            anteriormente, foram adquiridas no seu curso de pós-graduação?*
          </h6>
          <textarea
            cols="35"
            rows="5"
            id="otherSkills"
            placeholder="Escreva aqui..."
            value={otherSkills}
            maxlength="1000"
            onChange={(event) => setOtherSkills(event.target.value)}
          />
        </div>
        <div className="strong-points-container-pg4">
          <h6>
            22) Na sua opinião, quais foram os pontos fortes do curso de
            pós-graduação para sua formação?*
          </h6>
          <textarea
            cols="35"
            rows="5"
            id=" strongPoints"
            placeholder="Escreva aqui..."
            value={strongPoints}
            maxlength="1000"
            onChange={(event) => setStrongPoints(event.target.value)}
          />
        </div>
        <div className="graduation-improve-container-pg4">
          <h6>
            24) Avalio que a formação adquirida no curso de pós-graduação
            melhorou minhas chances de atuação profissional:*
          </h6>
          <div className="graduation-improve-checkbox-1-pg4">
            <input
              type="radio"
              name="graduationImproveType"
              id="Sim"
              value="Sim"
              onChange={(event) => setGraduationImprove(event.target.value)}
            />
            <label for="graduationImprove">Sim</label>
          </div>
          <div className="graduation-improve-checkbox-2-pg4">
            <input
              type="radio"
              name="graduationImproveType"
              id="Nao"
              value="Não"
              onChange={(event) => setGraduationImprove(event.target.value)}
            />
            <label for="graduationImprove">Não</label>
          </div>
        </div>
        <div className="product-developed-container-pg4">
          <h6>
            26) O produto do meu curso de pós-graduação foi desenvolvido com a
            utilização de conhecimentos adquiridos durante o curso?*
          </h6>
          <div className="graduation-improve-checkbox-1-pg4">
            <input
              type="radio"
              name="knoledgeType"
              id="Sim"
              value="Sim"
              onChange={(event) => setProductDeveloped(event.target.value)}
            />
            <label for="productDeveloped">Sim</label>
          </div>
          <div className="graduation-improve-checkbox-2-pg4">
            <input
              type="radio"
              name="knoledgeType"
              id="Nao"
              value="Não"
              onChange={(event) => setProductDeveloped(event.target.value)}
            />
            <label for="productDeveloped">Não</label>
          </div>
        </div>
        <div className="help-partnership-container-pg4">
          <h6>28) O produto foi desenvolvido com alguma ajuda ou parceria?*</h6>
          <div className="graduation-improve-checkbox-1-pg4">
            <input
              type="radio"
              name="partinershipType"
              id="Sim"
              value="Sim"
              onChange={(event) => setHelpPartnership(event.target.value)}
            />
            <label for="helpPartnership">Sim</label>
          </div>
          <div className="graduation-improve-checkbox-2-pg4">
            <input
              type="radio"
              name="partinershipType"
              id="Nao"
              value="Não"
              onChange={(event) => setHelpPartnership(event.target.value)}
            />
            <label for="helpPartnership">Não</label>
          </div>
        </div>
        <div className="pleased-container-pg4">
          <h6>30) Você se sente satisfeito com a conclusão do seu curso?*</h6>
          <div className="graduation-improve-checkbox-1-pg4">
            <input
              type="radio"
              name="finishCourseType"
              id="Sim"
              value="Sim"
              onChange={(event) => setPleased(event.target.value)}
            />
            <label for="pleased">Sim</label>
          </div>
          <div className="graduation-improve-checkbox-2-pg4">
            <input
              type="radio"
              name="finishCourseType"
              id="Nao"
              value="Não"
              onChange={(event) => setPleased(event.target.value)}
            />
            <label for="pleased">Não</label>
          </div>
        </div>
      </div>

      <div className="right-column-pg4">
        <div className="wanted-skills-container-pg4">
          <h6>
            21) Quais outras competências, que não são as listadas
            anteriormente, você gostaria de ter adquirido no seu curso de
            pós-graduação?*
          </h6>
          <textarea
            cols="35"
            rows="5"
            id="wantedSkills"
            placeholder="Escreva aqui..."
            value={wantedSkills}
            maxlength="1000"
            onChange={(event) => setWantedSkills(event.target.value)}
          />
        </div>
        <div className="waek-points-container-pg4">
          <h6>
            23) Na sua opinião, quais foram os pontos fracos do curso de
            pós-graduação para sua formação?*
          </h6>
          <textarea
            cols="35"
            rows="5"
            id="weakPoints"
            placeholder="Escreva aqui..."
            value={weakPoints}
            maxlength="1000"
            onChange={(event) => setWeakPoints(event.target.value)}
          />
        </div>
        <div className="professional-rise-container-pg4">
          <h6>
            25) Avalio que a formação adquirida no curso de pós-graduação
            melhorou minhas chances de ascensão profissional:*
          </h6>
          <div className="professional-rise-checkbox-1-pg4">
            <input
              type="radio"
              name="fornmationType"
              id="Sim"
              value="Sim"
              onChange={(event) => setProfessionalRise(event.target.value)}
            />
            <label for="professionalRise">Sim</label>
          </div>
          <div className="professional-rise-checkbox-2-pg4">
            <input
              type="radio"
              name="fornmationType"
              id="Nao"
              value="Não"
              onChange={(event) => setProfessionalRise(event.target.value)}
            />
            <label for="professionalRise">Não</label>
          </div>
        </div>
        <div className="professional-applicability-container-pg4">
          <h6>
            27) O produto apresenta aplicabilidade no meu local de trabalho?*
          </h6>
          <div className="professional-rise-checkbox-1-pg4">
            <input
              type="radio"
              name="productWorkPlaceType"
              id="Sim"
              value="Sim"
              onChange={(event) => setProductApplicability(event.target.value)}
            />
            <label for="professionalRise">Sim</label>
          </div>
          <div className="professional-rise-checkbox-2-pg4">
            <input
              type="radio"
              name="productWorkPlaceType"
              id="Nao"
              value="Não"
              onChange={(event) => setProductApplicability(event.target.value)}
            />
            <label for="professionalRise">Não</label>
          </div>
        </div>
        <div className="professional-rise-container-pg4">
          <h6>29) Você reconhece a aplicabilidade do seu produto?*</h6>
          <div className="professional-rise-checkbox-1-pg4">
            <input
              type="radio"
              name="productType"
              id="Sim"
              value="Sim"
              onChange={(event) => setApplicability(event.target.value)}
            />
            <label for="applicability">Sim</label>
          </div>
          <div className="professional-rise-checkbox-2-pg4">
            <input
              type="radio"
              name="productType"
              id="Nao"
              value="Não"
              onChange={(event) => setApplicability(event.target.value)}
            />
            <label for="applicability">Não</label>
          </div>
        </div>
      </div>
    </form>
  );
}
