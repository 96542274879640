import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";

import { Pagination } from "../../../components/Pagination";
import { LoadingPage } from "../../Loading";

import "./styles.css";

export default function Result(props) {
  const navigate = useNavigate();
  const [resumesData, setResumesData] = useState([]);
  const [numberOfResumes, setNumberOfResumes] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = props.loading;
  const applyFilter = props.applyFilter;

  const state = props.state;
  const city = props.city;
  const graduation = props.graduation;
  const posGraduation = props.posGraduation;
  const conclusionYear = props.conclusionYear;

  const limit = 6;

  useEffect(() => {
    const fetchUserData = async () => {
      await api
        .get(
          `/resume/listAll?state=${state}&city=${city}&graduation=${graduation}&posGraduation=${posGraduation}&conclusionYear=${conclusionYear}&limit=${limit}&page=${currentPage}`)
        .then((res) => {
          setResumesData(res.data.userData);
          setNumberOfResumes(res.data.numberOfResumes);
          setLoading(false);
        });
    };

    if (loading) fetchUserData();
  }, [applyFilter, currentPage]);

  const handleSelectResume = (userEmail) => {
    navigate("/curriculo", { state: { email: userEmail } });
  };

  return (
    <div className="resultados-container">
      <div className="resultados-title">
        <h2>Resultados</h2>
      </div>

      {loading ? (
        <LoadingPage />
      ) : (
        resumesData.map((user) => {
          return (
              <div
                className="student"
                onClick={() => handleSelectResume(user.email)}
              >
                <div className="student-profile">
                  <div className="student-name">{user.name}</div>
                  <div className="student-profession">{user.profession}</div>
                </div>

                <div className="line"></div>

                <div className="student-graduation">
                  <span>Graduação</span>
                  <p className="graduation-course">{user.course}</p>
                  <span>
                    Formação em {new Date(user.graduationYear).getFullYear()}
                  </span>
                </div>
              </div>
          );
        })
        )}

      <Pagination 
        postsPerPage={limit} 
        totalPosts={numberOfResumes} 
        currentPage={currentPage} 
        setCurrentPage={setCurrentPage}
        setLoading={setLoading}
      />
    </div>
  );
}
