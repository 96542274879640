import "./styles.css";
import { BiError } from "react-icons/bi";

export default function ErrorAlert() {
  return (
    <div className="NotFoundContainer">
      <div className="NotFoundBox">
        <BiError size={80} />
        <h1>Erro 404</h1>
        <h2>Página não encontrada!</h2>
      </div>
    </div>
  );
}