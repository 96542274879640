import React, { useEffect, useState } from "react";

import "./styles.css";

export function Cidades({ state, setCity }) {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios?orderBy=nome`)
        .then((res) => res.json())
        .then((data) => {
          const citiesArr = data.map((city) => city.nome);
          setCities(citiesArr);
        });
    }

    if(state) fetchCities();
  }, [state]);

  return(
    <div className="cities-container">
      <h5>Cidade</h5>
      <select onChange={(event) => setCity(event.target.value)}>
        <option value="">Selecione</option>
        {
          cities.map((city) => {
            return (
              <option key={city} value={city}>{city}</option>
            );
          })
        }
      </select>
    </div>
  );
}
