import FadeLoader from "react-spinners/FadeLoader";
import "./styles.css";

export default function SpinnerLogo() {
  return (
    <div className="SpinnerLogoContainer">
      <FadeLoader />
    </div>
  );
}
