import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoadingPage } from "../Loading";
import api from "../../services/api";

import logoImg from "../../assets/logo.svg";
import "./styles.css";

export function Curriculo() {
  const location = useLocation();
  const { email } = useSelector((state) => state.UserReducer);
  const { role } = useSelector((state) => state.UserReducer);
  const [loading, setLoading] = useState(true);
  const [filledForms, setFilledForms] = useState(false);

  const [emailUser, setEmailUser] = useState("");
  const [name, setName] = useState("");
  const [profession, setProfession] = useState("");
  const [historicProfession, setHistoricProfession] = useState("");
  const [historicAcademic, setHistoricAcademic] = useState("");
  const [website, setWebsite] = useState("");
  const [skills, setSkills] = useState("");
  const [awards, setAwards] = useState("");
  const [phone, setPhone] = useState("");
  const [linkedin, setLinkedin] = useState("");
	const [age, setAge] = useState(0);
	const [city, setCity] = useState("");
	const [state, setState] = useState("");

  useEffect(() => {
    const isFormsFilled = async () => {
      await api.get(`/forms/fetchUser/${email}`).then((res) => {
        const formsRes = res.data.filled;
        setFilledForms(formsRes);
        setLoading(false);
      });
    };

    if(loading) isFormsFilled();
  }, [loading, email]);

  useEffect(() => {
    const fetchResumeData = async (emailToSearch) => {
      await api
        .get("/resume/list", {
          params: {
            email: emailToSearch,
          },
        })
        .then((res) => {
          setEmailUser(res.data.email);
          setName(res.data.name);
          setProfession(res.data.profession);
          setHistoricProfession(res.data.historicProfession);
          setHistoricAcademic(res.data.historicAcademic);
          setWebsite(res.data.website);
          setSkills(res.data.skills);
          setAwards(res.data.awards);
          setPhone(res.data.phone);
          setLinkedin(res.data.linkedin);
					setAge(res.data.age);
					setCity(res.data.city);
					setState(res.data.state);

          setLoading(false);
        });
    };

    if (loading && role === "student") {
      fetchResumeData(email);
    } else if((loading && role === "company" )|| (loading && role === "admin")) {
      const emailLocation = location.state.email;
      fetchResumeData(emailLocation);
    }
  }, [loading]);

  return loading ? (
    <LoadingPage />
  ) : name ? (
    <div className="curriculo-container">
      <header>
        <a href="/">
          <img src={logoImg} alt="logo" className="responsive-image" />
        </a>
      </header>
      <button type="button" className="menu-button"></button>
      <div className="cyan-square">
        <div className="texto-mobile">Curriculo</div>
      </div>
      <div className="black-square">
        <div className="content">
        
        <div className="row-curriculo">
          <div className="column-curriculo">
            <div className="nome">{name}</div>
            <div className="profissão">{profession}</div>
            <div className="white-square"></div>
          </div>
          
        </div>
        
          <div className="title">Perfil Pessoal:</div>
          <div className="subtitle">{age} anos</div>
          <div className="subtitle">{city} - {state}</div>
          
        </div>
        <div className="premiações_in">PREMIAÇÕES:</div>
        <div className="lista-quadrado_in">
          <p>{awards}</p>
        </div>
      </div>
      <div className="outside">
        <div className="subtitle-out">Histórico profissional</div>
        <div className="items"><p>{historicProfession}</p></div>
        <div className="subtitle-out">Histórico acadêmico</div>
        <div className="items"><p>{historicAcademic}</p></div>
        <div className="subtitle-out">Habilidades e Competências</div>
        <div className="items"><p>{skills}</p></div>
      </div>
      <div className="black-square2">
        <div className="title">INFORMAÇÕES PARA CONTATO:</div>
        <div className="subtitle">Tel: {phone}</div>
        <div className="subtitle">E-mail: {emailUser}</div>
        <div className="subtitle">Linkedin: <a href={linkedin}>{linkedin}</a></div>
        <div className="subtitle">Website: <a href={website}>{website}</a></div>
      </div>
      <div className="grid-container">
        <div className="return">
          <button type="button" className="voltar-button">
          <a href="/">Voltar</a>
          </button>
        </div>
        <div className="concluir">
          <button type="button" className="end">
          <a href="/">Concluir</a>
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="cadastrar-curriculo">
      <header>
        <a href="/">
          <img src={logoImg} alt="logo" className="responsive-image" />{" "}
        </a>
      </header>
      <div className="sem-dados">
        <h2>Currículo não cadastrado.</h2>
        <br />
          {
            filledForms ? 
              <p>
                Para cadastrar dados em seu currículo, acesse a página{" "}
                <a href="/curriculoForms">Currículo</a>.
              </p> : 
              <p>
                Para cadastrar dados em seu currículo, primeiro responda ao nosso{" "}
                <a href="/tcle">formulário</a>.
              </p>
          }
      </div>
    </div>
  );
}
