const INITIAL_STATE = {
  logged: false,
  email: '',
  role: '',
};

export const UserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'setUserLogged':
      return { ...state, logged: action.payload };

    case 'setEmail':
      return { ...state, email: action.payload };

    case 'setRole':
      return { ...state, role: action.payload };

    case 'resetUserState':
      return INITIAL_STATE;

    default: return state;
  }
}
