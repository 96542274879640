import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";

import { LoadingPage } from "../../Loading";
import { Pagination } from "../../../components/Pagination";

import "./styles.css";

export function FormsList() {
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfForms, setNumberOfForms] = useState(0);
  const limit = 6;

  useEffect(() => {
    const fetchForms = async () => {
      await api.get(`/forms/index?limit=${limit}&page=${currentPage}`).then((formsData) => {
        setForms(formsData.data.forms);
        setNumberOfForms(formsData.data.numberOfForms);
        setLoading(false);
      });
    };

    if (loading) fetchForms();
  }, [loading, currentPage]);

  const handleSelectForms = (userEmail) => {
		navigate("/tabela", {state: {email: userEmail}});
	}

  return loading ? (
    <LoadingPage />
  ) : (
    <div className="forms-list-container">
      <ul className="forms-ul-container">
        {forms.map((forms) => {
          return (
            <li key={forms.name} onClick={() => handleSelectForms(forms.email)}>
              <div className="forms-info">
                <div className="name">{forms.name}</div>

                <div className="line"></div>

                <div className="graduation">
                  <span>Graduação</span>
                  <p>{forms.graduation}</p>
                  <span>Formação em {new Date(forms.graduationYear).getFullYear()}</span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <Pagination
        postsPerPage={limit}
        totalPosts={numberOfForms}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLoading={setLoading}
      />
    </div>
  );
}
