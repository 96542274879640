import React, { useState } from "react";

const Cursos = (props) => {
  const [course, setCourse] = props.setCourse;
  const [selectCourse, setSelectCourse] = useState('');

  return (
    <div>
      <br />
      <select className="select-course" name="Cursos" onChange={(event) => {
          setSelectCourse(event.target.value)
          setCourse(event.target.value)
        }}>
        <option value="">Selecione</option>
        <option value="Medicina">Medicina</option>
        <option value="Enfermagem">Enfermagem</option>
        <option value="Fisioterapia">Fisioterapia</option>
        <option value="Biomedicina">Biomedicina</option>
        <option value="Biologia">Ciências Biológicas-Biologia</option>
        <option value=" ">Outro</option>
      </select>

      {
        selectCourse === " " ? 
          <div className="graduation-course-pg2">
            <label htmlFor="activities">Outro (Especifique):</label>
            <input
              type="text"
              id="Outro"
              maxLength="40"
              onChange={(event) => {setCourse(event.target.value)}}
            />
          </div> :
          <></>
      }
    </div>
  );
};

export default Cursos;
