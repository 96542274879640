import React from "react";

const Estados = (props) => {
  const setState = props.setState;

  return (
    <div>
      <p>Estado</p>
      <select name="estado" onChange={(event) => setState(event.target.value)}>
        <option value="">Selecione</option>
        <option value="RO">RO</option>
        <option value="AC">AC</option>
        <option value="AM">AM</option>
        <option value="RR">RR</option>
        <option value="PA">PA</option>
        <option value="AP">AP</option>
        <option value="TO">TO</option>
        <option value="MA">MA</option>
        <option value="PI">PI</option>
        <option value="CE">CE</option>
        <option value="RN">RN</option>
        <option value="PB">PB</option>
        <option value="PE">PE</option>
        <option value="AL">AL</option>
        <option value="SE">SE</option>
        <option value="BA">BA</option>
        <option value="MG">MG</option>
        <option value="ES">ES</option>
        <option value="RJ">RJ</option>
        <option value="SP">SP</option>
        <option value="PR">PR</option>
        <option value="SC">SC</option>
        <option value="MS">MS</option>
        <option value="MT">MT</option>
        <option value="GO">GO</option>
        <option value="DF">DF</option>
        <option value="RS">RS</option>
      </select>
    </div>
  );
};

export default Estados;
