// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import foto0 from "../../assets/imagemHome.svg";
import foto1 from "../../assets/foto1.PNG";
import foto2 from "../../assets/foto2.PNG";
import foto3 from "../../assets/foto3.PNG";
import foto4 from "../../assets/foto4.PNG";
import foto5 from "../../assets/foto5.PNG";
import foto6 from "../../assets/foto6.PNG";

// Import Swiper styles
import 'swiper/css';

export function Carousel() {
  return (
    <Swiper
      slidesPerView={1}
      modules={[Autoplay]}
      speed={1200}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <img src={foto0} alt="home" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={foto1} alt="One" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={foto2} alt="Two" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={foto3} alt="Three" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={foto4} alt="Four" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={foto5} alt="Five" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={foto6} alt="Six" />
      </SwiperSlide>
    </Swiper>
  );
};
