import logoImg from "../../assets/logo.svg";

import "./styles.css";

export function Footer({ logged, role, userName, companyEmail, handleLogout }) {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logoImg} alt="logo" />
          <div className="copyright">
            <p>Todos os direitos reservados. &#169; UNESP</p>
            <p>
              Desenvolvido por{" "}
              <a href="https://www.linkedin.com/company/infojr/mycompany/">
                InfoJR.
              </a>
            </p>
          </div>
        </div>

        <div className="footer-menus">
          {logged && (role === "student" || role === "admin") ? (
            <div className="menu-logged">
              <ul>
                <li>
                  <span>Olá, {userName}</span>
                </li>

                <li>
                  {role === "admin" ? (
                    <>
                      <a href="listaForms">Visualizar respostas de formulário</a>
                      <br />
                      <br />
                      <a href="filtro">Filtro de Egressos</a>
                    </>
                  ) : (
                    <a href="curriculo">Ver Currículo</a>
                  )}
                </li>

                <li>
                  <button onClick={handleLogout}>Sair</button>
                </li>
              </ul>
            </div>
          ) : logged && role === "company" ? (
            <div className="menu-logged">
              <ul>
                <li>
                  <span>Logado: {companyEmail}</span>
                </li>

                <li>
                  <a href="filtro">Filtro de Egressos</a>
                </li>

                <li>
                  <button onClick={handleLogout}>Sair</button>
                </li>
              </ul>
            </div>
          ) : (
            <>
              <div>
                <h3>Sobre nós</h3>
                <ul>
                  <li>
                    <a href="SobreNos">Equipe</a>
                  </li>
                </ul>
              </div>

              <div>
                <h3>Egressos</h3>
                <ul>
                  <li>
                    <a href="loginegresso">Login</a>
                  </li>

                  <li>
                    <a href="cadastroaluno">Cadastro</a>
                  </li>

                  <li>
                    <a href="curriculo">Currículo</a>
                  </li>
                </ul>
              </div>

              <div>
                <h3>Empresas</h3>
                <ul>
                  <li>
                    <a href="loginempresas">Login</a>
                  </li>

                  <li>
                    <a href="cadastroempresa">Cadastro</a>
                  </li>

                  <li>
                    <a href="filtro">Filtro de Egressos</a>
                  </li>
                </ul>
              </div>
            </>
          )}
          <div>
            <h3>Contato</h3>
            <ul>
              <li>
                <a href="mailto:<nowiki>edulink.fmb@unesp.br?">Fale com a gente!</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
