import React from "react";

import Estados from "../../../components/estados"
import { Cidades } from "../../../components/Cidades";

import "./styles.css";

export function Pg1(props) {
  const [name, setName] = props.setName;
  const [socialName, setSocialName] = props.setSocialName;
  const [age, setAge] = props.setAge;
  const setCity = props.setCity;
  const [state, setState] = props.setState;

  return (
    <form className="answer-form-pg1">
      <h6> Nome completo*</h6>
      <input type="text" value={name} maxLength="80" onChange={event => setName(event.target.value)} />

      <h6>Nome social (opcional)</h6>
      <input type="text" value={socialName} maxLength="80" onChange={event => setSocialName(event.target.value)}/>

      <div className="row-container-pg1">
        <div className="age-container-pg1">
          <h6>Idade*</h6>
          <input type="number" min="17" value={age} onChange={event => setAge(event.target.value)}/>
        </div>
        <div className="uf-container-pg1">
          <Estados 
            setState={setState}
          />
        </div>
      </div>
      <Cidades
        state={state}
        setCity={setCity}
      />
    </form>
  );
}