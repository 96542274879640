import React from "react";
import { TcleModal } from "./TcleModal";

export function TCLE() {
  return (
    <>
      <TcleModal/>
    </>
  );
}
