import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io"

import "./styles.css";

export default function Voltar(props) {
  const path = props.path

  return (
    <div className="voltar-container">
      <a href={`../${path}`}>
        <div className="voltar">
          <IoMdArrowRoundBack/>
          <p>Voltar</p>
        </div>
      </a>
    </div>
  );
}
