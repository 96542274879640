import React from "react";
import { ToastContainer } from "react-toastify";
import { AppRoutes } from "./routes/routes";
import CookieConsent from "react-cookie-consent";
import "./global.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <CookieConsent 
        overlay 
        expires={1} 
        buttonText="Confirmar"
        location="top"
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: "10px"
        }}
        buttonStyle={{ background: "#247BA0", color:"white" }}
      >
        Esse site utiliza Cookies.
      </CookieConsent>
      <AppRoutes />
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
