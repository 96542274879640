import React from "react";
import logoImg from "../../assets/logo.svg";
import "./styles.css";
import fotoRenan from "../../assets/foto-Renan.jpeg"
import fotoGuilherme from "../../assets/foto-Guilherme.jpeg"
import fotoMarcos from "../../assets/foto-Marcos.jpeg"
import fotoRafael from "../../assets/foto-Rafael.jpg"
import fotovictor from "../../assets/foto-Victor.jpeg"
import fotoAna from "../../assets/foto-Ana.jpg"
import fotoTamiris from "../../assets/foto-Tamiris.jpg"

export function SobreNos() {
  return (
    <div className='about-container'>
      <div className='header'>
      <header>
        <a href="/"><img src={logoImg} alt="logo"/> </a>
      </header>
        <h1>Sobre nós</h1>
      </div>
      <div className='about'>
        <div className="text-about">
        Com as recentes regulamentações acerca da atuação do profissional da área da saúde, 
        por consequência o profissional da área de biotecnologia passa a ter novas perspectivas sobre o mercado de trabalho e sua atuação profissional, 
        sendo este um momento propício para fomentar ferramentas que auxiliem os programas de Pós-graduação a conhecer o perfil de seus egressos bem como estreitar os laços com o mercado de trabalho. 
        Este site tem como objetivo desenvolver uma metodologia de avaliação de egressos de cursos de Pós-graduação Biotecnologia na área Médica. 
        Inicialmente foi feita uma pesquisa bibliográfica a fim de entender como são avaliados os egressos de cursos semelhantes no Brasil, 
        a partir dos resultados optou-se por implantar um questionário adaptado para este fim em formato digital, disponibilizado em um site. 
        Foram estruturadas as interfaces dos egressos, empresas e administrador e a etapa de programação foi feita por uma empresa júnior contratada utilizando a linguagem JavaScript, HTML5 e CSS (integrados pelo framework React JS). 
        Com a conclusão das etapas relacionadas a programação o instrumento está apto para ser aplicado no grupo piloto.
        </div>
        <div className="idealizadores-container">
          <h3>Idealizadores</h3>
          <div className='cards-container'></div>
          <div className="idealizadores">
            <div className="idealizador-1-profile">
              <img src={fotoTamiris} alt="logo" />
              <div className='idealizador-info'>
                <div className="idealizador-name">
                  Tamiris Desiderio
                </div>
                <div className="idealizador-profession">
                  Pesquisadora Principal 
                </div>
              </div>
            </div>

            <div className="line"></div>
            <div className="student-graduation">
              <div className="graduation-course">
                <a href="https://www.linkedin.com/in/tamiris-desiderio-aaa5b658/">LinkedIn</a>
                <br />
                <br />
                <a href=" http://lattes.cnpq.br/6642632230457853">Lattes</a>
              </div>
            </div>
          </div>
          <div className="idealizadores">
            <div className="idealizador-1-profile">
              <img src={fotoAna} alt="logo" />
              <div className='idealizador-info'>
                <div className="idealizador-name">
                  Ana Silva Ferreira
                </div>
                <div className="idealizador-profession">
                  Orientadora
                </div>
              </div>
            </div>

            <div className="line"></div>
            <div className="student-graduation">
              <div className="graduation-course">
                <a href="https://www.linkedin.com/in/ana-silvia-s-b-s-ferreira-87246b21/">LinkedIn</a>
                <br />
                <br />
                <a href="http://lattes.cnpq.br/7842202980920591">Lattes</a>
              </div>
            </div>
          </div>
        </div>

        <div className="idealizadores-container">
          <h3>Desenvolvedores</h3>
          <div className="idealizadores-victor">
            <div className="idealizador-1-profile">
              <img src={fotovictor} alt="logo" />
              <div className='idealizador-info'>
                <div className="idealizador-name">
                  Victor Rocha
                </div>
                <div className="idealizador-profession">
                  Diretor e Desenvolvedor Front-End
                </div>
              </div>
            </div>

            <div className="line"></div>
            <div className="student-graduation">
              <div className="graduation-course">
                <a href="https://www.linkedin.com/in/victor-rocha-8b39ba21a/">LinkedIn</a>
              </div>
            </div>
          </div>
          <div className="idealizadores">
            <div className="idealizador-1-profile">
              <img src={fotoGuilherme} alt="logo" />
              <div className='idealizador-info'>
                <div className="idealizador-name">
                  Guilherme Marques
                </div>
                <div className="idealizador-profession">
                  Desenvolvedor Full-Stack
                </div>
              </div>
            </div>

            <div className="line"></div>
            <div className="student-graduation">
              <div className="graduation-course">
                <a href="https://www.linkedin.com/in/guilherme-marques-a308611b8/">LinkedIn</a>
              </div>
            </div>
          </div>
          <div className="idealizadores">
            <div className="idealizador-1-profile">
              <img src={fotoRenan} alt="logo" />
              <div className='idealizador-info'>
                <div className="idealizador-name">
                  Renan Gabriel Morelli
                </div>
                <div className="idealizador-profession">
                  Desenvolvedor Front-End
                </div>
              </div>
            </div>

            <div className="line"></div>
            <div className="student-graduation">
              <div className="graduation-course">
                <a href="https://www.linkedin.com/in/renan-morelli-26ab54171/">LinkedIn</a>
              </div>
            </div>
          </div>

          <div className="idealizadores">
            <div className="idealizador-1-profile">
              <img src={fotoMarcos} alt="logo" />
              <div className='idealizador-info'>
                <div className="idealizador-name">
                  Marcos Paulo Somera
                </div>
                <div className="idealizador-profession">
                  Desenvolvedor Front-End
                </div>
              </div>
            </div>

            <div className="line"></div>
            <div className="student-graduation">
              <div className="graduation-course">
                <a href="https://www.linkedin.com/in/marcos-somera-15472223a/">LinkedIn</a>
              </div>
            </div>
          </div>
          <div className="idealizadores">
            <div className="idealizador-1-profile">
              <img src={fotoRafael} alt="logo" />
              <div className='idealizador-info'>
                <div className="idealizador-name">
                  Rafael Daiki
                </div>
                <div className="idealizador-profession">
                  Desenvolvedor Front-End
                </div>
              </div>
            </div>

            <div className="line"></div>
            <div className="student-graduation">
              <div className="graduation-course">
                <a href="https://www.linkedin.com/in/marcos-somera-15472223a/">LinkedIn</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}