import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../../services/api";

import { LoadingPage } from "../../Loading";

import "./styles.css";

export function TcleModal() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { email } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    const verifyAgreeTerms = async () => {
      await api.get("/user/index").then((res) => {
        const { user } = res.data;

        if(user && !user.tcle_agreement) {
          setIsLoading(false);
        } else {
          navigate("/formulario")
        }
      });
    }

    if(isLoading) {
      verifyAgreeTerms();
    }
  }, [email]);

  const handleAgreeTcle = async () => {
    await api.post("/user/accept_terms", {
      email,
    }).then((res) => {
        res.status === 200 ? navigate("/formulario") : navigate("/");
      }).catch((err) => {
        navigate("/");
      }
    );
  }
  
  return isLoading ? (
    <LoadingPage/>
  ) : (
    <Modal
      isOpen={true}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      ariaHideApp={false}
    >
      <div className="modal-container">
        <header>
          <h3>
            TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO (TCLE)
          </h3>
          <h4>
            RESOLUÇÃO 466/2012
          </h4>
        </header>
        <div className="tcle-content">
          <span>
            CONVIDO, o(a) Senhor(a) para participar do Projeto de Pesquisa intitulado “AVALIAÇÃO DE EGRESSOS DE CURSOS DE PÓS-GRADUAÇÃO EM BIOTECNOLOGIA: DESENVOLVIMENTO DA METODOLOGIA ALMALAUREA”, que será desenvolvido por mim, Tamiris Mariani Pereira Desiderio, Bióloga, Assistente Técnico Administrativo I da Pró-reitoria de Pesquisa da Unesp, com orientação da Professora Doutora Ana Silvia Sartori Barraviera Seabra da Faculdade de Medicina de Botucatu –UNESP, pelo curso de Doutorado Profissional em Pesquisa e Desenvolvimento (Biotecnologia Médica). 
            <br/>
            <br/>
            Benefícios esperados: os atuais e futuros alunos do programa serão beneficiados por sua importante colaboração, pois a implementação deste instrumento trará devolutiva para a Instituição, possibilitando aos gestores um conhecimento das demandas dos alunos bem como suas necessidades de melhorias. Assim, sua contribuição será significativa para a melhoria da Universidade. Para isso, necessito que responda algumas questões, o que levará aproximadamente 15 minutos.
            Desconforto e riscos: sua participação não trará riscos a sua saúde ou segurança. Fique ciente de que sua participação neste estudo é voluntária e que, mesmo após ter dado seu consentimento para participar da pesquisa, você poderá retira-lo a qualquer momento, sem qualquer prejuízo. Está garantido o ressarcimento de qualquer prejuízo que sua participação possa causar. 
            <br/>
            <br/>
            Este Termo de Consentimento Livre e Esclarecido será elaborado em formato digital e a via rubricada será armazenada podendo ser solicitada a qualquer momento aos pesquisadores por um período de 5 anos após o término da pesquisa.
            <br/>
            <br/>
            De acordo com a Lei Geral de Proteção de Dados – LGPD, informamos que este trabalho utiliza as informações solicitadas para fins exclusivamente acadêmicos e científicos e os pesquisadores atestam que são responsáveis pelo sigilo e armazenamento das informações pessoais dos mesmos, sendo que os dados poderão ser publicados e a identidade dos participantes será mantida em sigilo. Qualquer comunicação por meio virtual terá só um remetente e um destinatário, ou será realizado na forma de lista oculta, para que não seja possível a identificação dos participantes nem a visualização dos seus dados por terceiros, somente o pesquisador responsável terá acesso aos registros no formulário online. 
            <br/>
            <br/>
            Qualquer dúvida adicional você poderá entrar em contato com o Comitê de Ética em Pesquisa através dos telefones (14) 3880-1608 ou 3880-1609 que funciona de 2ª a 6ª feira das 8h às 11h30 e das 14h às 17h, na Chácara Butignolli s/nº em Rubião Júnior – Botucatu - São Paulo. Os dados de localização dos pesquisadores estão abaixo descritos:
            <br/>
            <br/>
            <strong>CONCORDO EM PARTICIPAR</strong> <br/>
            Após terem sido sanadas todas minhas dúvidas a respeito deste estudo, CONCORDO EM PARTICIPAR de forma voluntária, estando ciente que todos os meus dados estarão resguardados através do sigilo que os pesquisadores se comprometeram. Estou ciente que os resultados desse estudo poderão ser publicados em revistas científicas sem, no entanto, que minha identidade seja revelada.
            <br/>
            <br/>
            Botucatu,__/_/___
            <br/>
            <br/>
            ________			__________<br/>
            Nome: Tamiris Mariani Pereira Desiderio<br/>
            Endereço: Rua Dr. José Barbosa de Barros, 1540 <br/>
            Telefone: 14 99733.8040<br/>
            Email: tamiris.desiderio@unesp.br<br/>
            <br/>
            Nome: Ana Silvia Sartori Barraviera Seabra<br/>
            Endereço: Rua Siqueira Campos, 372 - Centro<br/>
            Telefone: 14 996513380<br/>
            Email: ana.ferreira@unesp.br
          </span>
          <div className="modal-buttons">
            <button
              className="agree-tcle"
              onClick={handleAgreeTcle}
            >
              Concordo
            </button>
            <button
              className="cancel-tcle"
              onClick={() => navigate("/")}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
