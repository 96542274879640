import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import api from "../../services/api";

import logoImg from "../../assets/logo.svg";
import { Pg1 } from "./Pg1";
import { Pg2 } from "./Pg2";
import { Pg3 } from "./Pg3";
import { Pg4 } from "./Pg4";
import "./styles.css";

const steps = [
  {
    id: "INITIAL",
    title: "Identificação inicial ",
    subtitle: "Seus dados iniciais",
  },
  {
    id: "GRADUATION",
    title: "Informações sobre a graduação",
  },
  {
    id: "PROFESSIONAL",
    title: "Organização de atuação laboral",
    subtitle: "Aqui você irá responder questões sobre o seu local de trabalho atual",
  },
  {
    id: "OPINIONS",
    title: "",
    subtitle: "Para finalizar, por favor, opine sobre as questões abaixo:",
  },
];

export function Form() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { email } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    const userForms = async () => {
      await api.get(`/forms/fetchUser/${email}`).then((res) => {
        const filledForms = res.data.filled;
        if (filledForms) {
          navigate("/");
        }
        setLoading(false);
      });
    };

    if (loading) userForms();
  }, [loading, email]);

  //Data from first page:
  const [name, setName] = useState("");
  const [socialName, setSocialName] = useState("");
  const [city, setCity] = useState("");
  const [age, setAge] = useState("");
  const [state, setState] = useState("");

  //Data from second page:
  const [graduationDate, setGraduationDate] = useState("");
  const [course, setCourse] = useState("");
  const [posGraduationCourse, setPosGraduationCourse] = useState("");
  const [institution, setInstitution] = useState("");
  const [professionalActivity, setProfessionalActivity] = useState("");
  const [wantToWork, setWantToWork] = useState("");
  const [timeWorked, setTimeWorked] = useState(0);
  const [workedInstitution, setWorkedInstitution] = useState("");

  //Data from third page:
  const [administration, setAdministration] = useState("");
  const [acess, setAcess] = useState("");
  const [hours, setHours] = useState("");
  const [organization, setOrganization] = useState("");
  const [activities, setActivities] = useState([]);
  const [activitiesText, setActivitiesText] = useState("");
  const [workRegime, setWorkRegime] = useState("");
  const [benefits, setBenefits] = useState([]);
  const [benefitsText, setBenefitsText] = useState("");
  const [satisfaction, setSatisfaction] = useState("");
  const [opportunities, setOpportunities] = useState("");
  const [firstJob, setFirstJob] = useState("");
  const [jobBeginning, setJobBeginning] = useState("");
  const [salary, setSalary] = useState("");
  const [changeJob, setChangeJob] = useState("");
  const [keepJob, setKeepJob] = useState("");
  const [quitJob, setQuitJob] = useState("");
  const [help, setHelp] = useState("");
  const [upgrades, setUpgrades] = useState("");

  //Data from last page:
  const [otherSkills, setOtherSkills] = useState("");
  const [wantedSkills, setWantedSkills] = useState("");
  const [strongPoints, setStrongPoints] = useState("");
  const [weakPoints, setWeakPoints] = useState("");
  const [graduationImprove, setGraduationImprove] = useState("");
  const [professionalRise, setProfessionalRise] = useState("");
  const [productDeveloped, setProductDeveloped] = useState("");
  const [productApplicability, setProductApplicability] = useState("");
  const [helpPartnership, setHelpPartnership] = useState("");
  const [applicability, setApplicability] = useState("");
  const [pleased, setPleased] = useState("");

  let page1Data = {
    name: name,
    socialName: socialName,
    age: age,
    state: state,
    city: city,
  };

  let page2Data = {
    question1: graduationDate,
    question2Graduation: course,
    question2PosGraduation: posGraduationCourse,
    question3: institution,
    question4: professionalActivity,
    question5: wantToWork,
    question6Months: timeWorked,
    question6Type: workedInstitution,
  };

  let page3Data = {
    question7: administration,
    question8: acess,
    question9: hours + " horas",
    question10: organization,
    question11: activitiesText,
    question12: workRegime,
    question13: benefitsText,
    question14: satisfaction,
    question15: opportunities,
    question16: firstJob,
    question17: jobBeginning,
    question18: salary,
    question19a: changeJob,
    question19b: keepJob,
    question19c: quitJob,
    questionIncentive: help,
    questionImprovements: upgrades,
  };

  let page4Data = {
    question20: otherSkills,
    question21: wantedSkills,
    question22: strongPoints,
    question23: weakPoints,
    question24: graduationImprove,
    question25: professionalRise,
    question26: productDeveloped,
    question27: productApplicability,
    question28: helpPartnership,
    question29: applicability,
    question30: pleased,
  };

  const saveForms = async () => {
    const dataToSave = {
      email: email,
      ...page1Data,
      ...page2Data,
      ...page3Data,
      ...page4Data,
    };

    await api
      .post("/forms/register", dataToSave)
      .then((res) => {
        alert("Suas respostas foram salvas com sucesso!");
        navigate("/agradecimentos");
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  const handleSaveForms = async (e) => {
    await api
      .post("/forms/fourthPage", {
        ...page4Data,
      })
      .then((res) => {
        saveForms();
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
          return;
        }
      });
  };

  const [currentStep, setCurrentStep] = useState(0);

  async function handleNext() {
    switch (currentStep) {
      case 0:
        await api
          .post("/forms/firstPage", {
            ...page1Data,
          })
          .then((res) => {
            setCurrentStep((prevState) => prevState + 1);
          })
          .catch((error) => {
            if (error.response) {
              toast(error.response.data.message);
            }
          });
        break;
      case 1:
        await api
          .post("/forms/secondPage", {
            ...page2Data,
          })
          .then((res) => {
            if (professionalActivity === "Nunca atuei profissionalmente") {
              setCurrentStep((prevState) => prevState + 2);
            } else {
              setCurrentStep((prevState) => prevState + 1);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast(error.response.data.message);
            }
          });
        break;
      case 2:
        await api
          .post("/forms/thirdPage", {
            ...page3Data,
          })
          .then((res) => {
            setCurrentStep((prevState) => prevState + 1);
          })
          .catch((error) => {
            if (error.response) {
              toast(error.response.data.message);
            }
          });
        break;

      default:
        break;
    }
  }
  function handlPrevius() {
    if (
      currentStep > 1 &&
      professionalActivity === "Nunca atuei profissionalmente"
    ) {
      setCurrentStep((prevState) => prevState - 2);
    } else {
      setCurrentStep((prevState) => prevState - 1);
    }
  }
  const value = currentStep;
  return (
    <div className="form-container-generic">
      <header>
        <img src={logoImg} alt="logo" />
      </header>

      <h1>Questionário</h1>
      <div className="progressBar">
        <CircularProgressbar
          value={value}
          maxValue={4}
          text={`${(value / 4) * 100}%`}
        />
      </div>

      <div className="answer-container-generic">
        <h2>{steps[currentStep].title}</h2>
        <h6>{steps[currentStep].subtitle}</h6>

        {steps[currentStep].id === "INITIAL" && (
          <Pg1
            setName={[name, setName]}
            setSocialName={[socialName, setSocialName]}
            setCity={setCity}
            setAge={[age, setAge]}
            setState={[state, setState]}
          />
        )}

        {steps[currentStep].id === "GRADUATION" && (
          <Pg2
            setGraduationDate={[graduationDate, setGraduationDate]}
            setInstitution={setInstitution}
            setProfessionalActivity={[
              professionalActivity,
              setProfessionalActivity,
            ]}
            setWantToWork={setWantToWork}
            setTimeWorked={[timeWorked, setTimeWorked]}
            setWorkedInstitution={setWorkedInstitution}
            setPosGraduationCourse={[
              posGraduationCourse,
              setPosGraduationCourse,
            ]}
            setCourse={[course, setCourse]}
          />
        )}
        {steps[currentStep].id === "PROFESSIONAL" && (
          <Pg3
            setAdministration={setAdministration}
            setHours={[hours, setHours]}
            setActivities={[activities, setActivities]}
            setActivitiesText={[activitiesText, setActivitiesText]}
            setSatisfaction={setSatisfaction}
            setOpportunities={setOpportunities}
            setSalary={setSalary}
            setChangeJob={setChangeJob}
            setKeepJob={setKeepJob}
            setQuitJob={setQuitJob}
            setHelp={[help, setHelp]}
            setAcess={setAcess}
            setOrganization={[organization, setOrganization]}
            setWorkRegime={setWorkRegime}
            setBenefits={[benefits, setBenefits]}
            setBenefitsText={[benefitsText, setBenefitsText]}
            setFirstJob={setFirstJob}
            setJobBeginning={setJobBeginning}
            setUpgrades={[upgrades, setUpgrades]}
          />
        )}
        {steps[currentStep].id === "OPINIONS" && (
          <Pg4
            setOtherSkills={[otherSkills, setOtherSkills]}
            setWantedSkills={[wantedSkills, setWantedSkills]}
            setStrongPoints={[strongPoints, setStrongPoints]}
            setWeakPoints={[weakPoints, setWeakPoints]}
            setGraduationImprove={setGraduationImprove}
            setProfessionalRise={setProfessionalRise}
            setProductDeveloped={setProductDeveloped}
            setProductApplicability={setProductApplicability}
            setHelpPartnership={setHelpPartnership}
            setApplicability={setApplicability}
            setPleased={setPleased}
          />
        )}
        <div className="buttons">
          {currentStep > 0 && (
            <button
              className="buttonPrevius"
              type="button"
              onClick={handlPrevius}
            >
              Anterior
            </button>
          )}
          {currentStep < steps.length - 1 && (
            <button className="buttonNext" type="button" onClick={handleNext}>
              Próximo
            </button>
          )}
          {currentStep === steps.length - 1 && (
            <button
              className="buttonSubmit"
              type="submit"
              onClick={() => handleSaveForms()}
            >
              Confirmar
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
