import React from "react";
import ErrorAlert from "./ErrorAlert";
import "./styles.css";

export function NotFound() {
  return (
    <React.Fragment>
      <div className="NotFoundRootContainer">
        <ErrorAlert />
      </div>
    </React.Fragment>
  );
}