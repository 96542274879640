import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

import "./styles.css";

import logoImg from "../../assets/logo.svg";

export function CurriculoForms() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { email } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    const isFormsFilled = async () => {
      await api.get(`/forms/fetchUser/${email}`).then((res) => {
        const formFilled = res.data.filled;
        if (!formFilled) navigate('/');
        setLoading(false);
      })
    }

    if (loading) isFormsFilled();
  }, [loading, email, navigate]);

  const [profession, setProfession] = useState("");
  const [phone, setPhone] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [historicProfession, setHistoricProfession] = useState("");
  const [historicAcademic, setHistoricAcademic] = useState("");
  const [website, setWebsite] = useState("");
  const [skills, setSkills] = useState("");
  const [awards, setAwards] = useState("");

  const ExitAlert = () => {
    if (window.confirm("As alterações feitas até o momento não serão salvas, tem certeza que deseja sair?")) navigate("/");
  }

  const handleSaveResume = async (event) => {
    event.preventDefault();

    const data = {
      email: email,
      profession: profession,
      historicProfession: historicProfession,
      historicAcademic: historicAcademic,
      website: website,
      skills: skills,
      awards: awards,
      phone: phone,
      linkedin: linkedin,
    };


    await api.post("/resume/register", data)
      .then((res) => {
        alert("Currículo salvo com sucesso!");
        navigate("/");
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  return (
    <div className="form-container-curriculo" >
      <header>
        <a href="/">
          <img src={logoImg} alt="logo" />{" "}
        </a>
      </header>
        <h1>Currículo</h1>
        <form className="answer-form-curriculo">
          <div className="row-container-1-curriculo">
            <div className="profession-curriculo">
              <h6>Profissão: *</h6>
              <input
                type="text"
                placeholder="Escreva aqui..."
                value={profession}
                onChange={(event) => setProfession(event.target.value)}
              ></input>
            </div>
            <div className="phone-curriculo">
              <h6>Telefone: </h6>
              <input
                type="tel"
                placeholder="Escreva aqui..."
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                pattern="[0-9]{2}-[0-9]{5}-[0-9]{4}"
              ></input>
            </div>
          </div>
          <div className="row-container-1-curriculo">
            <div className="historic-profession-curriculo">
              <h6>Descreva sobre seu histórico profissional: *</h6>
              <textarea
                cols="35"
                rows="5"
                placeholder="Escreva aqui..."
                maxlength="1000"
                value={historicProfession}
                onChange={(event) => setHistoricProfession(event.target.value)}
              ></textarea>
            </div>
            <div className="linkedin-curriculo">
              <h6>LinkedIn:</h6>
              <input
                type="url"
                placeholder="Escreva aqui..."
                value={linkedin}
                onChange={(event) => setLinkedin(event.target.value)}
              ></input>
            </div>
          </div>
          <div className="row-container-1-curriculo">
            <div className="historic-academic-curriculo">
              <h6>Descreva sobre seu histórico acadêmico: *</h6>
              <textarea
                cols="35"
                rows="5"
                placeholder="Escreva aqui..."
                maxlength="1000"
                value={historicAcademic}
                onChange={(event) => setHistoricAcademic(event.target.value)}
              ></textarea>
            </div>
            <div className="Website-curriculo">
              <h6>Website:</h6>
              <input
                type="url"
                placeholder="Escreva aqui..."
                value={website}
                onChange={(event) => setWebsite(event.target.value)}
              ></input>
            </div>
          </div>
          <div className="row-container-1-curriculo">
            <div className="skills-curriculo">
              <h6>Habilidades e Competências: *</h6>
              <textarea
                cols="35"
                rows="5"
                placeholder="Escreva aqui..."
                value={skills}
                maxlength="1000"
                onChange={(event) => setSkills(event.target.value)}
              ></textarea>
            </div>
            <div className="awards-curriculo">
              <h6>Premiações:</h6>
              <textarea
                cols="35"
                rows="5"
                placeholder="Escreva aqui..."
                value={awards}
                maxlength="1000"
                onChange={(event) => setAwards(event.target.value)}
              ></textarea>
            </div>

          </div>
          
        

        <div className="row-container-2-curriculo">
          <button className="buttonExit" onClick={ExitAlert}>
            Sair
          </button>
          <button className="buttonFinish" onClick={handleSaveResume}>
            Finalizar
          </button>
        </div>
      </form>
    </div>
  );
}
