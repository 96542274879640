import axios from "axios";

const api = () => {
  const defaultOptions = {
    baseURL: "https://edulink.app.br/api",
    timeout: 10 * 1000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  let instance = axios.create(defaultOptions);

  return instance;
}

export default api();