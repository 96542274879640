import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Voltar from "../../components/Voltar";
import api from "../../services/api";
import { LoadingPage } from "../Loading";

import "./styles.css";

import logoImg from "../../assets/logo.svg";

export function Tabela() {
  const location = useLocation();
  const email = location.state.email;
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");

  const [question1, setQuestion1] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question2_1, setQuestion2_1] = useState("");
  const [question3, setQuestion3] = useState("");
  const [question4, setQuestion4] = useState("");
  const [question5, setQuestion5] = useState("");
  const [question6_1, setQuestion6_1] = useState(0);
  const [question6_2, setQuestion6_2] = useState("");
  const [question7, setQuestion7] = useState("");
  const [question8, setQuestion8] = useState("");
  const [question9, setQuestion9] = useState("");
  const [question10, setQuestion10] = useState("");
  const [question11, setQuestion11] = useState("");
  const [question12, setQuestion12] = useState("");
  const [question13, setQuestion13] = useState("");
  const [question14, setQuestion14] = useState("");
  const [question15, setQuestion15] = useState("");
  const [question16, setQuestion16] = useState("");
  const [question17, setQuestion17] = useState("");
  const [question18, setQuestion18] = useState("");
  const [question19_1, setQuestion19_1] = useState("");
  const [question19_2, setQuestion19_2] = useState("");
  const [question19_3, setQuestion19_3] = useState("");
  const [question19_4, setQuestion19_4] = useState("");
  const [question19_5, setQuestion19_5] = useState("");
  const [question20, setQuestion20] = useState("");
  const [question21, setQuestion21] = useState("");
  const [question22, setQuestion22] = useState("");
  const [question23, setQuestion23] = useState("");
  const [question24, setQuestion24] = useState("");
  const [question25, setQuestion25] = useState("");
  const [question26, setQuestion26] = useState("");
  const [question27, setQuestion27] = useState("");
  const [question28, setQuestion28] = useState("");
  const [question29, setQuestion29] = useState("");
  const [question30, setQuestion30] = useState("");

  useEffect(() => {
    const fetchAnswers = async () => {
      await api.get(`/forms/listAnswers/${email}`).then((res) => {
        const formsData = res.data.formsAnswers;
        setName(formsData.name);
        setQuestion1(formsData.question1);
        setQuestion2(formsData.question2Graduation);
        setQuestion2_1(formsData.question2PosGraduation);
        setQuestion3(formsData.question3);
        setQuestion4(formsData.question4);
        setQuestion5(formsData.question5);
        setQuestion6_1(formsData.question6Months);
        setQuestion6_2(formsData.question6Type);
        setQuestion7(formsData.question7);
        setQuestion8(formsData.question8);
        setQuestion9(formsData.question9);
        setQuestion10(formsData.question10);
        setQuestion11(formsData.question11);
        setQuestion12(formsData.question12);
        setQuestion13(formsData.question13);
        setQuestion14(formsData.question14);
        setQuestion15(formsData.question15);
        setQuestion16(formsData.question16);
        setQuestion17(formsData.question17);
        setQuestion18(formsData.question18);
        setQuestion19_1(formsData.question19a);
        setQuestion19_2(formsData.question19b);
        setQuestion19_3(formsData.question19c);
        setQuestion19_4(formsData.questionIncentive);
        setQuestion19_5(formsData.questionImprovements);
        setQuestion20(formsData.question20);
        setQuestion21(formsData.question21);
        setQuestion22(formsData.question22);
        setQuestion23(formsData.question23);
        setQuestion24(formsData.question24);
        setQuestion25(formsData.question25);
        setQuestion26(formsData.question26);
        setQuestion27(formsData.question27);
        setQuestion28(formsData.question28);
        setQuestion29(formsData.question29);
        setQuestion30(formsData.question30);
        setLoading(false);
      });
    };

    if (loading) fetchAnswers();
  }, []);

  return loading ? (
    <LoadingPage />
  ) : (
    <div className="table-container">
      <div className="img-header">
        <a href="/">
          <img src={logoImg} alt="logo" />{" "}
        </a>
      </div>
      <Voltar path={"listaForms"} />
      <div className="name-user">
        <h1>Formulário de {name}</h1>
      </div>
      <table>
        <tr>
          <th>PERGUNTAS</th>
          <th>RESPOSTAS</th>
        </tr>
        <tr>
          <td>1 - Mês e ano de conclusão do Curso de Pós-graduação?</td>
          <td>{`${new Date(question1).getDay()}/${
            new Date(question1).getMonth() + 1
          }/${new Date(question1).getFullYear()}`}</td>
        </tr>
        <tr>
          <td>2 - Qual é o seu curso de graduação?</td>
          <td>{question2}</td>
        </tr>
        <tr>
          <td>2.1 - Curso de pós-graduação?</td>
          <td>{question2_1}</td>
        </tr>
        <tr>
          <td>
            3 - Qual a natureza da instituição de ensino de sua graduação?
          </td>
          <td>{question3}</td>
        </tr>
        <tr>
          <td>4 - Condições de atividade profissional</td>
          <td>{question4}</td>
        </tr>
        <tr>
          <td>
            5 - Caso não esteja inserido em atividades da área de formação de
            seu curso de Pós-graduação, deseja se inserir profissionalmente em
            sua área no futuro?
          </td>
          <td>{question5}</td>
        </tr>
        <tr>
          <td>6.1 - Quanto tempo atuou profissionalmente?</td>
          <td>{question6_1}</td>
        </tr>
        <tr>
          <td>6.2 - Qual a natureza da instituição de atuação?</td>
          <td>{question6_2}</td>
        </tr>
        <tr>
          <td>7 - Nível da administração:</td>
          <td>{question7}</td>
        </tr>
        <tr>
          <td>8 - Forma de acesso:</td>
          <td>{question8}</td>
        </tr>
        <tr>
          <td>9 - Carga horária semanal até:</td>
          <td>{question9}</td>
        </tr>
        <tr>
          <td>10 - Natureza da organização:</td>
          <td>{question10}</td>
        </tr>
        <tr>
          <td>
            11 - Atividades que você desenvolve mais frequentemente nesta
            modalidade de inserção profissional:
          </td>
          <td>{question11}</td>
        </tr>
        <tr>
          <td>12 - Regime de trabalho:</td>
          <td>{question12}</td>
        </tr>
        <tr>
          <td>13 - Benefícios:</td>
          <td>{question13}</td>
        </tr>
        <tr>
          <td>14 - Como você se sente nesta organização?</td>
          <td>{question14}</td>
        </tr>
        <tr>
          <td>
            15 - Como você percebe as oportunidades para seu crescimento
            profissional nesta organização?
          </td>
          <td>{question15}</td>
        </tr>
        <tr>
          <td>
            16 - Este foi o seu primeiro emprego (trabalho) na sua área de
            estudo?
          </td>
          <td>{question16}</td>
        </tr>
        <tr>
          <td>17 - Este emprego teve início:</td>
          <td>{question17}</td>
        </tr>
        <tr>
          <td>18 - Renda mensal:</td>
          <td>{question18}</td>
        </tr>
        <tr>
          <td>19.1 - Gostaria de mudar de emprego, mantendo o que faço.</td>
          <td>{question19_1}</td>
        </tr>
        <tr>
          <td>
            19.2 - Gostaria de mudar dentro da minha área de estudo, mantendo o
            emprego.
          </td>
          <td>{question19_2}</td>
        </tr>
        <tr>
          <td>19.3 - Gostaria de não atuar mais no meu emprego.</td>
          <td>{question19_3}</td>
        </tr>
        <tr>
          <td>
            19.4 - Para concluir o seu curso você recebeu algum tipo de ajuda ou
            incentivo vindo da empresa ou instituição que trabalha?
          </td>
          <td>{question19_4}</td>
        </tr>
        <tr>
          <td>
            19.5 - Você Obteve quais melhorias decorrentes da sua atual
            formação?
          </td>
          <td>{question19_5}</td>
        </tr>
        <tr>
          <td>
            20 - Quais outras competências, que não as listadas anteriormente,
            foram adquiridas no seu curso de pós-graduação?
          </td>
          <td>{question20}</td>
        </tr>
        <tr>
          <td>
            21 - Quais outras competências, que não as listadas anteriormente,
            você gostaria de ter adquirido no curso de pós-graduação?
          </td>
          <td>{question21}</td>
        </tr>
        <tr>
          <td>
            22 - Na sua opinião, quais foram os pontos fortes do curso de
            pós-graduação para sua formação?
          </td>
          <td>{question22}</td>
        </tr>
        <tr>
          <td>
            23 - Na sua opinião, quais foram os pontos fracos do curso de
            pós-graduação para sua formação?{" "}
          </td>
          <td>{question23}</td>
        </tr>
        <tr>
          <td>
            24 - Avalio que a formação adquirida no curso de pós-graduação
            melhorou minhas chances de atuação profissional:
          </td>
          <td>{question24}</td>
        </tr>
        <tr>
          <td>
            25 - Avalio que a formação adquirida no curso de pós-graduação
            melhorou minhas chances de ascensão profissional:
          </td>
          <td>{question25}</td>
        </tr>
        <tr>
          <td>
            26 - O produto em meu curso de pós-graduação foi desenvolvido com a
            utilização de conhecimentos adquiridos durante o curso?
          </td>
          <td>{question26}</td>
        </tr>
        <tr>
          <td>
            27 - O produto apresenta aplicabilidade em meu local de trabalho?
          </td>
          <td>{question27}</td>
        </tr>
        <tr>
          <td>28 - O produto foi desenvolvido com ajuda ou alguma parceria?</td>
          <td>{question28}</td>
        </tr>
        <tr>
          <td>29 - Você reconhece a aplicabilidade de seu produto?</td>
          <td>{question29}</td>
        </tr>
        <tr>
          <td>30 - Você se sente satisfeito com a conclusão de seu curso?</td>
          <td>{question30}</td>
        </tr>
      </table>
    </div>
  );
}
