import React, { useState } from "react";
import logoImg from "../../assets/logo.svg";
import filtroImg from "../../assets/filtro.svg";
import "./styles.css";
import Sidebar from "./sidebar.js";
import Result from "./Resultado";
import Voltar from "../../components/Voltar";

export function Filtro() {
  const [applyFilter, setApplyFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [graduation, setGraduation] = useState("");
  const [posGraduation, setPosGraduation] = useState("");
  const [conclusionYear, setConclusionYear] = useState("");

  return (
    <div className="filtro-container">
      <header>
        <a href="/">
          <img src={logoImg} alt="logo" />{" "}
        </a>
      </header>

      <Voltar path={""}/>

      <div className="filtro-egressos">
        <div className="filtro-img">
          <img src={filtroImg} alt="filtro" />
        </div>
        <div className="filtro-title">
          <h1>Filtro de Egressos</h1>
        </div>
      </div>

      <div className="contornoc">
        <Result 
          state={state}
          city={city}
          graduation={graduation}
          posGraduation={posGraduation}
          conclusionYear={conclusionYear}
          applyFilter={applyFilter}
          loading={[loading, setLoading]}
        />
      </div>

      <div className="contornop">
        <Sidebar
          state={[state, setState]}
          city={setCity}
          graduation={setGraduation}
          posGraduation={setPosGraduation}
          conclusionYear={setConclusionYear}
          applyFilter={[applyFilter, setApplyFilter]}
          loading={setLoading}
        />
      </div>
    </div>
  );
}
