import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import "./styles.css"

import logoImg from "../../assets/logo.svg";

export function CadastroAlunos() {
	const navigate = useNavigate();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const { logged } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    if(logged) navigate('/');
  }, [logged, navigate]);
	
	const handleRegister = async (event) => {
		event.preventDefault();

		const data = {
			username: name,
			email: email,
			password: password,
			confirmPassword: confirmPassword,
		};

		await api.post("/user/register", data)
			.then(res => {
				window.location.href = "loginegresso"
			}).catch(error => {
				if(error.response) {
					toast.error(error.response.data.message);
				}
			})
	}

	return(
		<div className="cadastro-aluno-container">
			<header>
				<a href="/"><img src={logoImg} alt="logo"/> </a>
			</header>

				<form 
					className="cadastro-aluno-form"
					onSubmit={event => handleRegister(event)}
				>
						<h1>Cadastro de Egressos</h1>

						<p>Nome Completo</p>
						<input 
							type="text"
							placeholder="Nome Completo"
							value={name}
							onChange={event => setName(event.target.value)}
						/>

						<p>E-mail</p>
						<input 
							type="email"
							placeholder="Email"
							value={email}
							onChange={event => setEmail(event.target.value)}
						/>

						<p>Senha</p>
						<input 
							type="password"
							placeholder="Senha"
							value={password}
							onChange={event => setPassword(event.target.value)}
						/>

						<p>Confirmação Senha</p>
						<input 
							type="password"
							placeholder="Confirme sua senha"
							value={confirmPassword}
							onChange={event => setConfirmPassword(event.target.value)}
						/>

						<button type="submit">
							Cadastrar
						</button>
				</form>

				<a href="/loginegresso"> Já tenho conta</a>
		</div>
	);
}