import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api";

import { Login } from "../pages/Login";
import { Form } from "../pages/Form";
import { CadastroAlunos } from "../pages/CadastroAlunos";
import { CadastroEmpresas } from "../pages/CadastroEmpresas";
import { Curriculo } from "../pages/Curriculo";
import { Inicio } from "../pages/Inicio";
import { Filtro } from "../pages/Filtro";
import { Agradecimentos } from "../pages/Agradecimentos";
import { CurriculoForms } from "../pages/CurriculoForms";
import { LoginEmpresas } from "../pages/LoginEmpresas";
import { NotFound } from "../pages/NotFound";
import { LoadingPage } from "../pages/Loading/";
import { Tabela } from "../pages/Tabela";
import {LoginADM} from "../pages/ADM";
import { ListaForms } from "../pages/ListaForms";
import { SobreNos } from "../pages/SobreNos";
import { TCLE } from "../pages/TCLE";

export function AppRoutes() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.UserReducer);
  const { role } = useSelector((state) => state.UserReducer);
  
  useEffect(() => {
    const isLogged = async () => {
      await api.get("/valid-auth")
      .then(res => {
          const userRole = res.data.role;
          dispatch({ type: "setUserLogged", payload: true });
          dispatch({ type: "setRole", payload: userRole });
        }).catch(err => {
          dispatch({ type: "resetUserState" });
          setLoading(false);
        })
    }

    if (loading) isLogged();
  }, [loading]);

  useEffect(() => {
    const fetchUser = async () => {
      await api.get("/user/index").then(res => {
        const user = res.data.user;
        dispatch({ type: "setEmail", payload: user.email });
        setLoading(false);
      }).catch(err => {
        dispatch({ type: "resetUserState" });
        setLoading(false);
      })
    };

    const fetchCompany = async () => {
      await api.get("/company/index").then(res => {
        const company = res.data.company;
        dispatch({ type: "setEmail", payload: company.email });
        setLoading(false);
      }).catch(err => {
        dispatch({ type: "resetUserState" });
        setLoading(false);
      })
    }

    if (logged && (role === "student" || role === "admin")) {
      fetchUser();
    }
    else if(logged && role === "company") {
      fetchCompany();
    }
  }, [logged, role]);

  const ProtectedRoute = () => {
    return (
      logged ? <Outlet /> : <Navigate to="/"/>
    );
  };

  const StudentRoutes = () => {
    return (
      role === "student" ? <Outlet/> : <Navigate to="/"/>
    );
  };

  const CompanyRoutes = () => {
    return (
      role === "company" || role === "admin" ? <Outlet/> : <Navigate to="/"/>
    );
  }

  const AdminRoutes = () => {
    return (
      role === "admin" ? <Outlet/> : <Navigate to="/"/>
    );
  }

  return (
    loading ? <LoadingPage/> :
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/loginegresso" element={<Login/>}/>
          <Route path="/loginempresas" element={<LoginEmpresas/>} />
          <Route path="/cadastroempresa" element={<CadastroEmpresas/>} />
          <Route path="/cadastroaluno" element={<CadastroAlunos/>} />
          <Route path="/LoginADM" element={<LoginADM/>}/>
          <Route path="/SobreNos" element={<SobreNos/>}/>

          <Route element={<ProtectedRoute/>}>
            <Route path="/curriculo" element={<Curriculo/>} />

            <Route element={<StudentRoutes/>}>
              <Route path="/formulario" element={<Form/>} />
              <Route path="/agradecimentos" element={<Agradecimentos/>}/>
              <Route path="/curriculoForms" element={<CurriculoForms/>}/>
              <Route path="tcle" element={<TCLE/>}/>
            </Route>

            <Route element={<CompanyRoutes/>}>
              <Route path="/filtro" element={<Filtro/>} />
            </Route>

            <Route element={<AdminRoutes/>}>
              <Route path="/listaForms" element={<ListaForms/>}/>
              <Route path="/Tabela" element={<Tabela/>}/>
            </Route>
          </Route>
          
          <Route path="/*" element={<NotFound/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}