import React, { useState } from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";

import "./styles.css";

export function Pagination({ postsPerPage, totalPosts, currentPage, setCurrentPage, setLoading }) {
  const buttonLimit = 8;
  const pageNumbers = [];
  const [buttonsPage, setButtonsPage] = useState(1);
  
  for(let i=1; i<=Math.ceil(totalPosts/postsPerPage); i++)
    pageNumbers.push(i);

  const indexOfLastItem = buttonsPage * buttonLimit;
  const indexOfFirstItem = (indexOfLastItem - buttonLimit)+1;

  function handleRightButtonPage() {
    const newButtonPage = buttonsPage + 1;

    if(indexOfFirstItem+buttonLimit <= pageNumbers.length)
      setButtonsPage(newButtonPage);
  }

  function handleLeftButtonPage() {
    const newButtonPage = buttonsPage - 1;

    if(newButtonPage > 0) 
      setButtonsPage(newButtonPage);
  } 

  return (
    <nav className="nav-pagination">
      <GoChevronLeft
        className="left-arrow"
        size={23}
        onClick={() => handleLeftButtonPage()}
      />
      <ul>
        { pageNumbers.map((number) => {
            if(number >= indexOfFirstItem && number <= indexOfLastItem) {
              return (
                <li 
                  key={number}
                  onClick={() => {
                    setCurrentPage(number)
                    setLoading(true)
                  }}
                  className={currentPage === number ? "active" : null}
                >
                  {number} 
                </li>
              );
            } else {
              return null;
            }
        })
      }
      </ul>
      <GoChevronRight
        className="right-arrow"
        size={23}
        onClick={() => handleRightButtonPage()}
      />
    </nav>
  );
}
