import React from "react";

import "./styles.css";
import Cursos from "../../../components/cursos";
import { CursosPG } from "../../../components/CursosPg";

export function Pg2(props) {
  const [graduationDate, setGraduationDate] = props.setGraduationDate;
  const setInstitution = props.setInstitution;
  const [professionalActivity, setProfessionalActivity] =
    props.setProfessionalActivity;
  const setWantToWork = props.setWantToWork;
  const [timeWorked, setTimeWorked] = props.setTimeWorked;
  const setWorkedInstitution = props.setWorkedInstitution;
  const [posGraduationCourse, setPosGraduationCourse] =
    props.setPosGraduationCourse;
  const [course, setCourse] = props.setCourse;

  return (
    <form className="answer-form-pg2">
      <div className="row-container-pg2">
        <div className="left-column-pg2">
          <div className="graduation-date-pg2">
            <h6>1) Data de conclusão do curso*</h6>
            <input
              type="date"
              value={graduationDate}
              onChange={(event) => setGraduationDate(event.target.value)}
            />
          </div>
          <div className="institution-container-pg2">
            <h6>3) Natureza da instutuição*</h6>
            <div className="institution-checkbox-pg2">
              <input
                type="radio"
                name="institutionType"
                value="Pública"
                onChange={(event) => setInstitution(event.target.value)}
              />
              <label htmlFor="institution">Pública</label>
              <input
                type="radio"
                name="institutionType"
                value="Privada"
                onChange={(event) => setInstitution(event.target.value)}
              />
              <label htmlFor="institution">Privada</label>
            </div>
          </div>
          <div className="professional-activity-container-pg2">
            <h6>
              4) Condições de atividade profissional, caso nunca tenha atuado
              profissionalmente pularemos algumas questões da página seguinte*
            </h6>
            <div className="professional-activity-checkbox-1-pg2">
              <input
                type="radio"
                name="ProfessionalActivityType"
                id="professionalActivity"
                value="Nunca atuei profissionalmente"
                onChange={(event) =>
                  setProfessionalActivity(event.target.value)
                }
              />
              <label htmlFor="professionalActivity">
                Nunca atuei profissionalmente (dentro ou fora da área do meu
                curso de pós-graduação)
              </label>
            </div>
            <div className="professional-activity-checkbox-2-pg2">
              <input
                type="radio"
                name="ProfessionalActivityType"
                id="professionalActivity"
                value="Atualmente exerço atividades profissionais fora da área do meu curso de pós-graduação"
                onChange={(event) =>
                  setProfessionalActivity(event.target.value)
                }
              />
              <label htmlFor="professionalActivity">
                Atualmente exerço atividades profissionais fora da área do meu
                curso de pós-graduação
              </label>
            </div>
            <div className="professional-activity-checkbox-3-pg2">
              <input
                type="radio"
                name="ProfessionalActivityType"
                id="professionalActivity"
                value="Atualmente exerço atividades profissionais na área do meu curso de pós-graduação"
                onChange={(event) =>
                  setProfessionalActivity(event.target.value)
                }
              />
              <label htmlFor="professionalActivity">
                Atualmente exerço atividades profissionais na área do meu curso
                de pós-graduação
              </label>
            </div>

            <div className="professional-activity-container2-pg2">
              <h6>
                5) Caso não esteja inserido em atividades da área de formação de
                seu curso de pós-graduação, deseja se inserir profissionalmente
                em sua área no futuro?
              </h6>

              <div className="professional-activity-checkbox-1-pg2">
                <input
                  type="radio"
                  name="wantToWorkType"
                  className="wantToWork"
                  value="Sim"
                  onChange={(event) => setWantToWork(event.target.value)}
                />
                <label htmlFor="wantToWork">Sim</label>
              </div>
              <div className="professional-activity-checkbox-2-pg2">
                <input
                  type="radio"
                  name="wantToWorkType"
                  className="wantToWork"
                  value="Não"
                  onChange={(event) => setWantToWork(event.target.value)}
                />
                <label htmlFor="wantToWork">Não</label>
              </div>
            </div>
          </div>
          <div className="row2-container-pg2">
            {professionalActivity !== "Nunca atuei profissionalmente" &&
            professionalActivity !== "" ? (
              <>
                <div className="worked-area-container-pg2">
                  <h6>6) Se já trabalhou na área, responda:</h6>
                  <div className="worked-container-pg2">
                    <div className="time-worked-container-pg2">
                      <h6>Quanto tempo atuou?</h6>
                      <p>Meses</p>
                      <input
                        type="number"
                        min="1"
                        value={timeWorked}
                        onChange={(event) => setTimeWorked(event.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="instituition-worked-container-pg2">
                  <h6>Qual a natureza da instituição?</h6>
                  <div className="worked-checkbox-container-pg2">
                    <div className="worked-checkbox-1-pg2">
                      <input
                        type="radio"
                        name="WorkedInstitutionType"
                        id="workedInstitution"
                        value="Pública"
                        onChange={(event) =>
                          setWorkedInstitution(event.target.value)
                        }
                      />
                      <label htmlFor="workedInstitution">Pública</label>
                    </div>
                    <div className="worked-checkbox-2-pg2">
                      <input
                        type="radio"
                        name="WorkedInstitutionType"
                        id="workedInstitution"
                        value="Privada"
                        onChange={(event) =>
                          setWorkedInstitution(event.target.value)
                        }
                      />
                      <label htmlFor="workedInstitution">Privada</label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="right-column-pg2">
          <div className="graduation-course-pg2">
            <h6>2) Curso de graduação*</h6>
            <Cursos setCourse={[course, setCourse]} />
          </div>

          <div className="posgraduation-course-pg2">
            <h6>
              Caso possua curso de pós-graduação:
            </h6>
            <CursosPG setCourse={[posGraduationCourse, setPosGraduationCourse]} />
          </div>
        </div>
      </div>
    </form>
  );
}
