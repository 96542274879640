import React from "react";
import { LoginForm } from "./LoginForm";

export function LoginEmpresas() {
  return(
    <>
     <LoginForm />
    </>
  );
}
