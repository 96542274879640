import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

import "./style.css";

import logoImg from "../../assets/logo.svg";

export function CadastroEmpresas() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [cnpj, setCnpj] = useState('');
	const { logged } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    if(logged) navigate('/');
  }, [logged, navigate]);

	const handleRegister = async (event) => {
		event.preventDefault();

		const data = {
			email: email,
			password: password,
			confirmPassword: confirmPassword,
			cnpj: cnpj,
		};

		await api.post("/company/register", data)
			.then(event => {
				window.location.href = "/";
			}).catch(error => {
				if(error.response) {
					toast.error(error.response.data.message);
				}
			})
	}

	return(
		<div className="cadastro-container">
			<header>
				<a href="/"><img src={logoImg} alt="logo"/> </a>
			</header>

			<form 
				className="cadastro-form"
				onSubmit={event => handleRegister(event)}
			>
				<h1>Cadastro de Empresas</h1>

				<p>E-mail</p>
				<input 
					type="email"
					placeholder="Email"
					value={email}
					onChange={event => setEmail(event.target.value)}
				/>

				<p>Senha</p>
				<input 
					type="password" 
					placeholder="Senha"
					value={password}
					onChange={event => setPassword(event.target.value)}
				/>

				<p>Confirmar Senha</p>
				<input 
					type="password"
					placeholder="Confirme sua senha"
					value={confirmPassword}
					onChange={event => setConfirmPassword(event.target.value)}
				/>

				<p>CNPJ</p>
				<input 
					type="text"
					placeholder="CNPJ"
					value={cnpj}
					onChange={event => setCnpj(event.target.value)}
				/>

				<button type="submit">
					Cadastrar
				</button>
			</form>
			
			<a href="/loginempresas">Já tenho conta</a>
		</div>
  );
}
